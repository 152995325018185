//Realiza pedido seleccionando articulos desde combobox
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Cliente} from '../cliente/cliente';
import {BDService} from '../data/bd.service';
import {PedidoService} from '../pedido/pedido.service';
import {Detalle, Pedido} from '../pedido/pedido';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Articulo} from '../producto/Articulo';

interface Item {
  text: string,
  value: string,
}

@Component({
  selector: 'app-combo-pedido',
  templateUrl: './combo-pedido.component.html',
  styleUrls: ['./combo-pedido.component.css']
})
export class ComboPedidoComponent implements OnInit {

  cliente: Cliente;

  bdService: BDService;
  pedidoService: PedidoService;

  private subscripcion: any;

  id: number;
  pedido: string;

  pedidos: Pedido[];
  observa: string;
  precioLinea: string;
  precio: number;
  precioIva: number;
  precioAux: number;
  precioAuxi: number;
  categoria: number;
  private listItems: Item[];
  private listItemsTotales: Item[];
  private selectedItem: Item;
  promocion: string;
  unidades: number;
  cajas: number;
  articuloPersonalizado: Articulo;
  articulos: Articulo[];

  /*
  * @Input bdServic : servicio que gestiona indexedBD localizado en /data
  * @Input route: obtiene parametros de la url
  * @Input pedidoSer Realiza operaciones necesarias para realizar pedidos y mantiene persistencia de datos al navegar entre componentes
  * @Input router: controla navegacion entre paginas
  */
  constructor(private bdServic: BDService, private route: ActivatedRoute, private pedidoSer: PedidoService, private router: Router) {
    this.bdService = bdServic;
    this.pedidoService = pedidoSer;
    this.cliente = new Cliente();
    this.pedidos = [];
    this.precio = 0.000;
    this.precioAux = 0.000;
    this.precioAuxi = 0.000;
    this.observa = "";
    this.listItems = [{text: 'Escribe para crear articulo personalizado', value: "0"}];
    this.selectedItem = this.listItems[0];
    this.unidades = 0;
    this.cajas = 0;
    this.articuloPersonalizado = new Articulo();
    this.articulos = [];
    this.rellenaBuscador();
  }

  /*
  * Inicializa los arrays de articulos
  * Obtiene cliente de la url
 */
  ngOnInit() {
    const self = this;
    this.bdService.setName('BDPedidos');

    //(document.getElementById('textCantPed') as HTMLInputElement).value = "1";
    this.subscripcion = this.route.params.subscribe(params => {
      this.id = +params['id'];

      self.bdService.get('Clientes', this.id.toString(), "id_cliente").subscribe(
        (res) => {this.cliente = res,
          this.pedidoService.setCliente(res); },
        (err) => console.log(err),
        () =>  console.log('Done')

      );
    });
    self.bdService.all('Articulos', 'codigo_articulo').subscribe(arts => self.articulos = arts,
      () => console.log('error'),
      () => this.rellenaBuscador());
  }

  /*
 * Crea array con articulos pertenecientes a una categoria para rellenar combobox
 *  @input categoria: identificador categoria
  */
  rellenaBuscador() {
    this.pedidoService.aux = [];
    this.listItemsTotales = [];
    this.listItems = [];

    for (const entry of this.articulos) {
        this.listItemsTotales.push({text: entry.descripcion, value: entry.codigo_articulo});
    }
    this.listItems = this.listItemsTotales;
  }

  /*
* Se llama al elegir elemento del combobox y rellena linea con el valor del elemento seleccionado
* @input value: del combobox seleccionado
*/
  public selectionChangeBusca(value: any): void {
    this.selectionChange(value);
  }

  /*Reduce la lista de elementos del combobox si escribes*/
  public handleFilter(filtrado) {
     this.listItemsTotales = [];

    const filtro = filtrado.toLowerCase();
    console.log('entro ' + this.listItems[1].text);
    for (let entry of this.listItems) {
      if (((entry.text).toLowerCase().indexOf(filtro) >= 0) || ((entry.value).indexOf(filtro) >= 0)) {
        this.listItemsTotales.push({text: entry.text, value: entry.value});
      }
    }
  }
  /*
 * Crea array con articulos pertenecientes a una categoria para rellenar combobox
 *  @input categoria: identificador categoria
  */
  filtraCategoria(categoria: string) {
    this.categoria = Number(categoria);
    this.pedidoService.aux = [];
    this.listItems = [];

    if (this.categoria !== -1) {
      for (const entry of this.articulos) {
        if (Number(entry.codigo_categoria) === this.categoria) {
          this.pedidoService.aux.push(entry);
          this.listItems.push({text: entry.descripcion, value: entry.codigo_articulo});
        }
      }
    } else {
        this.pedidoService.aux = this.articulos;
        for (const entry of this.articulos) {
          this.listItems.push({text: entry.descripcion, value: entry.codigo_articulo});
        }
      }
    this.listItemsTotales = this.listItems;
    this.selectedItem = null;
  }

  /*
* Se llama al elegir elemento del combobox y rellena linea con el valor del elemento seleccionado
* @input value: del combobox seleccionado
 */
  public selectionChange(value: any): void {
    let self = this;
    let descue: number;
    descue = parseFloat((document.getElementById('textdescuento') as HTMLInputElement).value);

    if (value !== undefined) {
      self.bdServic.get('Articulos', value.value, 'codigo_articulo').subscribe(
        (res) => {
          this.unidades = this.pedidoService.calculaFormato(res.formato);
          this.cajas = 1;

          if (Number(this.cliente.mayorista)) {
            this.precio = res.precio_mayorista;
            this.precioAuxi = res.precio_mayorista;
            this.precioIva = res.precio_mayorista_con_iva;
          } else {
            this.precio = res.precio;
            this.precioAuxi = res.precio;
            this.precioIva = res.precio_con_iva;
          }
          this.precioAux = this.precio,
          this.promocion = res.predef1;
        },
      );
    }
  }

  /*
  * Permite introducir texto en el bombobox para hacer articulos con nombre personalizado
   */
  public valueNormalizer = (text: Observable<string>) => text.pipe(map((text: string) => {
    this.articuloPersonalizado.descripcion = text;
    return {
      value: -1,
      text: text
    };
  }))

  /*
  * Modifica el precio si cambia el descuento
   */
  cambiaPrecio(value: any) {
    let self = this;

     if (value !== undefined) {
      self.bdService.get('Articulos', value.value, 'codigo_articulo').subscribe(
        (res) => {
          this.revisaVcambiaPrecio(res);
        }
      );
    }
    console.log('cambia ' + this.pedidoService.descuento);
  }

  revisaVcambiaPrecio(res: Articulo) {
    if (this.pedidoService.v === 1) {
      this.precio = this.precioAuxi;
    } else {
      this.precio = this.precioIva;
    }
    //this.precioAux = this.precio;
  }

  /*
  * Llama a eligeProducto de pedido service pasandole un articulo de la BD o un articulo especial
  * que se crea al introducir descripcion personalizada
  * @input prod: id_articulo, identificador para encontrarlo en la BD o si es -1 saber que es en el Detalle del pedido el estado será "1"
   */
  eligeProducto(item: any) {
    let numAux: number;
    console.log('eli ' + this.pedidoService.descuento);
    const prod = item.value;
    numAux = Number(prod);

    if (numAux === -1) {
      console.log('numaux' + numAux);
      this.articuloPersonalizado.unidades = this.unidades.toString();
      this.articuloPersonalizado.precio = Number((document.getElementById('textPrecio') as HTMLInputElement).value);
      this.articuloPersonalizado.precio_mayorista = Number((document.getElementById('textPrecio') as HTMLInputElement).value);
      this.pedidoService.anyadeProducto(this.articuloPersonalizado, this.cajas);
    } else {
      let self = this;

       self.bdServic.get('Articulos', prod, 'codigo_articulo').subscribe(
         (res) => {
             res.unidades = this.unidades.toString(),
               //res.precio = Number((document.getElementById('textPrecio') as HTMLInputElement).value),
              //res.precio_mayorista = Number((document.getElementById('textPrecio') as HTMLInputElement).value),
             this.pedidoService.anyadeProducto(res, 0);
       }
       );
    }
    this.selectedItem = {text: 'Escribe para crear articulo personalizado', value: "0"};
  }

  setDescuento(articulo: Articulo) {
    this.pedidoService.descuento = '0';
    if (articulo.predef2 !== "") {
      this.pedidoService.descuento = articulo.predef2;
    }
  }

  /*
  * Permite modificar la cantidad de producto del input unidades
  * @input prod: articulo cuya cantidad cambia
   */
  cambiaArticuloCajas(prod: string) {
    let auxFormato;


    this.cajas = Number((document.getElementById('textCajas') as HTMLInputElement).value);

    if (Number(prod) !== -1) {
      this.bdService.get('Articulos', prod, 'codigo_articulo').subscribe(
        (res) => {
          auxFormato = res.formato,
            this.unidades = (this.pedidoService.calculaFormato(res.formato) * this.cajas);
            //this.precio = Number(res.precio_con_iva);
            //this.precioAux = this.precio ;
        },
        (err) => {
        },
        () => {
        }
      );
    }
  }

  /*
 * Permite modificar la cantidad de producto del input unidades
 * @input prod: articulo cuya cantidad cambia
  */
  cambiaArticuloUnidades(prod: string) {
    let auxFormato;

    this.unidades = Number((document.getElementById('textCantPed') as HTMLInputElement).value);

    if (Number(prod) !== -1) {
      this.bdService.get('Articulos', prod, 'codigo_articulo').subscribe(
        (res) => {
          auxFormato = res.formato,
            this.cajas = Math.floor(this.unidades / this.pedidoService.calculaFormato(res.formato));
          //this.precio = Number(res.precio_con_iva);
          //this.precioAux = this.precio ;
        },
        (err) => {
        },
        () => {
        }
      );
    }
  }
////////////////////////////////////////////////////////////////////////////////////////////
  /// Modal Descuento
  /////////////////////////////////////////////////////////////////////////////////////////
  /*
  * Al pulsar que si se cambia el descuento
  * @input cambia: etiqueta de la alert empleada para cerrarlo
  */
  confirm(cambia: any): void {
    let descue;
    cambia.hide();
    descue = parseFloat((document.getElementById('textdescuento') as HTMLInputElement).value);
    this.pedidoService.descuento = descue;
     this.pedidoService.descuentoAux = descue;
    console.log('confir ' + this.pedidoService.descuento);
  }

  /*
 * Al pulsar que no se cambia el descuento
 * @input cambia: etiqueta de la alert empleada para cerrarlo
 */
  decline(cambia: any): void {
    this.pedidoService.message = 'Declined!';
    cambia.hide();
     this.pedidoService.descuento = this.pedidoService.descuentoAux;
    (document.getElementById('textdescuento') as HTMLInputElement).value =
      this.pedidoService.redondeaNumero(this.pedidoService.descuento, 2);
  }

  /////////////////////////////////////////////////////////////////////////////////
  // Modal precio
  ////////////////////////////////////////////////////////////////////////////////

  /*
  * Al pulsar que si se cambia el descuento
  * @input cambia: etiqueta de la alert empleada para cerrarlo
  */
  /*
  confirmaPrecio(cambia: any): void {
    let precio;
    let des;
    cambia.hide();
    precio = parseFloat((document.getElementById('textPrecio') as HTMLInputElement).value);
    des = parseInt((document.getElementById('textdescuento') as HTMLInputElement).value);

    if(des === 1) {
      precio = this.precioAuxi;
    }
    this.precio = precio;
    this.precioAux = precio;
  }

  /*
 * Al pulsar que no se cambia el descuento
 * @input cambia: etiqueta de la alert empleada para cerrarlo
 *//*
  declinaPrecio(cambia: any): void {
    cambia.hide();
    (document.getElementById('textPrecio') as HTMLInputElement).value = this.precioAux.toString();
    this.precio = this.precioAux;
  }*/

  /*
  * Navega a la vista catalogo pedidoCatalogo.component
   */
  CatalogoPedido(bdService: BDService) {
    this.router.navigate(['pedidoCatalogo', this.id]);
  }

 /* ngOnDestroy() {
    this.subscripcion.unsubscribe();
  }*/
}
