// Clase para almacenar las categorias y emitir un eventos al seleccionarlas o filtrarlas
import {Component, OnInit, HostListener, Output, Input, AfterViewInit} from '@angular/core';
import { BDService } from '../data/bd.service';
import { Categoria } from './categoria';
import { EventEmitter } from '@angular/core';

interface Item {
  text: string,
  value: string,
}

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})

export class CategoriaComponent implements OnInit{
    categorias: Categoria[];
    aux: Categoria[];
    databaseCreated: boolean;
    listCategoria: Array<Item>;
    selectedItem: Item;

    @Output() idCategoria = "";
    @Output() CategoriaChange: EventEmitter<string> = new EventEmitter< string>();

  /*
  * @Input bdService : servicio que gestiona indexedBD localizado en /data
  */
  constructor(private bdService : BDService) {
    this.listCategoria = [];
  }

  /*
  * Inicializa array de categoria y rellena combobox
   */
  ngOnInit() {
   // Create the IndexedDB database and perform few operations.
      let self = this;

      this.bdService.setName('BDPedidos');

      self.bdService.create().subscribe(done => {
          self.databaseCreated = true;
      })

       // Extrae cliente
       self.bdService.all('Categorias', "codigo_categoria").subscribe(cat => self.categorias = cat);
       self.bdService.all('Categorias', "codigo_categoria").subscribe(cat => {self.aux = cat,
         this.creaListaCombobox(self.aux);
       });

  }

  /*
  * Rellena combobox
  * @input aux: pasa valores con los que rellena
   */
  creaListaCombobox(aux: Categoria[]) {
    this.listCategoria.push({text: 'TODOS LOS ARTICULOS', value: '-1'});
    for (const entry of aux) {
      this.listCategoria.push({text: entry.nombre, value: entry.codigo_categoria});
    }
  }

  /*
  * Se llama al elegir elemento del combobox y envia eventos al componente que corresponda con el valor del elemento seleccionado
  * @input value: Valor del combobox seleccionado
   */
  public selectionChange(value: any): void {
    if (value !== undefined) {
      this.idCategoria = value.value;
    } else {
      this.idCategoria = '-1';
    }
    this.CategoriaChange.emit(this.idCategoria);
  }

  /*
  * Se llama al escribir en el combobox filtrando por los elementos que contengan los caracteres
   */
  public handleFilter(filtro) {
    this.listCategoria = [];
    const reg = new RegExp(filtro);
    for (let entry of this.categorias) {
      if ((entry.nombre).toLowerCase().indexOf(filtro) >= 0) {
        this.listCategoria.push({text: entry.nombre, value: entry.codigo_categoria});
      }
    }
  }
}
