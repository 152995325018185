import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router) {
    // eliminar mensaje al cambiar de componente
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // mantiene el mensaje solo una vez
          this.keepAfterNavigationChange = false;
        } else {
          // elimina alerta
          this.subject.next();
        }
      }
    });
  }

  /*
  * @ input: message: mensaje mostrado pantalla
   * @ input: keepAfterNavigationChange = se pone a false para no mostrar el mensaje mas de en esta pantalla
   */
  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message });
  }

  /*
 * @ input: message: mensaje mostrado pantalla
  * @ input: keepAfterNavigationChange = se pone a false para no mostrar el mensaje mas de en esta pantalla
  */
  error(message: string, keepAfterNavigationChange = false) {
    message = this.personalizaMensaje(message);
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'error', text: message });
  }

  /*
  * Muestra mensaje
   */
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  personalizaMensaje(message: string): string {
    if (message.indexOf('Http failure response for (unknown url): 0') >= 0) {
      message = 'No hay internet';
    }
    return message;
  }
}
