//Clase en la que se almacenan los clientes pasados del servidor
export class Cliente {
  id_cliente: string;
  codigo_cliente: string;
  nombre: string;
  nombre_comercial: string;
  direccion: string;
  poblacion: string;
  codigo_provincia: string;
  cpostal: string;
  telefono1: string;
  telefono2: string;
  fax: string;
  cif: string;
  contacto: string;
  nombre_bco: string;
  direccion_bco: string;
  poblacion_bco: string;
  provincia_bco: string;
  entidad: string;
  agencia: string;
  cuenta: string;
  observaciones: string;
  codigo_forma_pago: string;
  dia_pago1: string;
  dia_pago2: string;
  dia_pago3: string;
  codigo_comercial: string;
  descuento: string;
  descuento_pronto_pago: string;
  morosos: string;
  riesgo: string;
  copfac: string;
  codigo_rappel: string;
  serie_factura: string; //A-N
  cuenta_remesa: string;
  tipo_iva: string;
  req: string;
  subcuenta: string;
  cuenta_ventas: string;
  mayorista: string;
  select: string;
  riesgoalc: string;
  etiquetas: string;
  tipo_direccion: string;
  email: string;
  password: string;
  nacionalidad: string;
  nacinalidad_bco: string;
  codigo_grupo: string;
  codigo_divisa: string;
  codigo_transportista: string;
  cuenta_vens: string;
  subcuenta_supl: string;
  subcuenta_ant: string;
  iva_incluido: string;
  fecha_inicio_vacaciones: string;
  fecha_fin_vacaciones: string;
  codigo_edi: string;
  descuento_especial: string;
  coppre: string;
  copped: string;
  copalb: string;
  copabo: string;
  copprec: string;
  telefono_bco: string;
  fax_bco: string;
  contacto_bco: string;
  observa1_bco: string;
  observa2_bco: string;
  bloqueado: string;
  descripcion_bloqueado: string;
  rinforma: string;
  efactura: string;
  envio_electronico: string;
  ctaantnic: string;
  cpostal_bco: string;
  clave: string;
  replegal: string;
  nifrlegal: string;
  timestamp: Date;
  libre1: any;
  libre2: any;
  libre3: any;
  libre4: any;
  libre5: any;
  libre6: any;
  libre7: any;
  libre8: any;
  libre9: any;
  libre10: any;

  constructor(id_cliente?: string, codigo_cliente?: string, nombre?: string, nombre_comercial?: string, direccion?: string, poblacion?: string, codigo_provincia?: string, cpostal?: string, telefono1?: string, telefono2?: string,
              fax?: string, cif?: string, contacto?: string, nombre_bco?: string, direccion_bco?: string, poblacion_bco?: string, provincia_bco?: string, entidad?: string, agencia?: string,
              cuenta?: string, observaciones?: string, codigo_forma_pago?: string, dia_pago1?: string, dia_pago2?: string, dia_pago3?: string, codigo_comercial?: string, descuento?: string,
              descuento_pronto_pago?: string, morosos?: string, riesgo?: string, copfac?: string, codigo_rappel?: string, serie_factura?: string, cuenta_remesa?: string, tipo_iva?: string,
              req?: string, subcuenta?: string, cuenta_ventas?: string, mayorista?: string, select?: string, riesgoalc?: string, etiquetas?: string, tipo_direccion?: string, email?: string,
              password?: string, nacionalidad?: string, nacinalidad_bco?: string, codigo_grupo?: string, codigo_divisa?: string, codigo_transportista?: string, cuenta_vens?: string,
              subcuenta_supl?: string, subcuenta_ant?: string, iva_incluido?: string, fecha_inicio_vacaciones?: string, fecha_fin_vacaciones?: string, codigo_edi?: string, descuento_especial?: string,
              coppre?: string, copped?: string, copalb?: string, copabo?: string, copprec?: string, telefono_bco?: string, fax_bco?: string, contacto_bco?: string, observa1_bco?: string, observa2_bco?: string,
              bloqueado?: string, descripcion_bloqueado?: string, rinforma?: string, efactura?: string, envio_electronico?: string, ctaantnic?: string, cpostal_bco?: string, clave?: string,
              replegal?: string, nifrlegal?: string, timestamp?: Date, libre1?: any,
              libre2?: any, libre3?: any, libre4?: any, libre5?: any, libre6?: any, libre7?: any, libre8?: any, libre9?: any, libre10?: any) {

    this.id_cliente = id_cliente || "0";
    this.codigo_cliente = codigo_cliente || null;
    this.nombre = nombre || null;
    this.nombre_comercial = nombre_comercial || "";
    this.direccion = direccion || "";
    this.poblacion = poblacion || "";
    this.codigo_provincia = codigo_provincia || "";
    this.cpostal = cpostal || "";
    this.telefono1 = telefono1 || "";
    this.telefono2 = telefono2 || "";
    this.fax = fax || "";
    this.cif = cif || "";
    this.contacto = contacto || "";
    this.nombre_bco = nombre_bco || "";
    this.direccion_bco = direccion_bco || "";
    this.poblacion_bco = poblacion_bco || "";
    this.provincia_bco = provincia_bco || "";
    this.entidad = entidad || "";
    this.agencia = agencia || "";
    this.cuenta = cuenta || "";
    this.observaciones = observaciones || "";
    this.codigo_forma_pago = codigo_forma_pago || "";
    this.dia_pago1 = dia_pago1 || "0";
    this.dia_pago2 = dia_pago2 || "0";
    this.dia_pago3 = dia_pago3 || "0";
    this.codigo_comercial = codigo_comercial || "0";
    this.descuento = descuento || "5";
    this.descuento_pronto_pago = descuento_pronto_pago || "0";
    this.morosos = morosos || "0";
    this.riesgo = riesgo || "0";
    this.copfac = copfac || "1";
    this.codigo_rappel = codigo_rappel || " ";
    this.serie_factura = serie_factura || "A";
    this.cuenta_remesa = cuenta_remesa || "01";
    this.tipo_iva = tipo_iva || "1";
    this.req = req || " ";
    this.subcuenta = subcuenta || " ";
    this.cuenta_ventas = cuenta_ventas || "700";
    this.mayorista = mayorista || "1";
    this.select = select || " ";
    this.riesgoalc = riesgoalc || "0";
    this.etiquetas = etiquetas || "1";
    this.tipo_direccion = tipo_direccion || "0";
    this.email = email || " ";
    this.password = password || " ";
    this.nacionalidad = nacionalidad || "ESPA";
    this.nacinalidad_bco = nacinalidad_bco || " ";
    this.codigo_grupo = codigo_grupo || "0";
    this.codigo_divisa = codigo_divisa || "EUR";
    this.codigo_transportista = codigo_transportista || "0";
    this.cuenta_vens = cuenta_vens || " ";
    this.subcuenta_supl = subcuenta_supl || " ";
    this.subcuenta_ant = subcuenta_ant || " ";
    this.iva_incluido = iva_incluido || " ";
    this.fecha_inicio_vacaciones = fecha_inicio_vacaciones || " ";
    this.fecha_fin_vacaciones = fecha_fin_vacaciones || " ";
    this.codigo_edi = codigo_edi || "";
    this.descuento_especial = descuento_especial || "0";
    this.coppre = coppre || "1";
    this.copped = copped || "1";
    this.copalb = copalb || "1";
    this.copabo = copabo || "1";
    this.copprec = copprec || "1";
    this.telefono_bco = telefono_bco || "";
    this.fax_bco = fax_bco || "";
    this.contacto_bco = contacto_bco || "";
    this.observa1_bco = observa1_bco || "";
    this.observa2_bco = observa2_bco || "";
    this.bloqueado = bloqueado || "";
    this.descripcion_bloqueado = descripcion_bloqueado || '';
    this.rinforma = rinforma || "";
    this.efactura = efactura || "";
    this.envio_electronico = envio_electronico || "";
    this.ctaantnic = ctaantnic || "";
    this.cpostal_bco = cpostal_bco || "";
    this.clave = clave || "1";
    this.replegal = replegal || "";
    this.nifrlegal = nifrlegal || "";
    this.timestamp = timestamp || new  Date();
    this.libre1 = libre1 || "";
    this.libre2 = libre2 || "";
    this.libre3 = libre3 || "";
    this.libre4 = libre4 || "";
    this.libre5 = libre5 || "";
    this.libre6 = libre6 || "";
    this.libre7 = libre7 || "";
    this.libre8 = libre8 || "";
    this.libre9 = libre9 || "";
    this.libre10 = libre10 || "";
  }
}
/*
export class NotasCliente {
  codigo_cliente: string;
  notas: string[];

  constructor(codigo_cliente?: string, notas?: string[]) {
    this.codigo_cliente = codigo_cliente || "99999";
    this.notas = notas || [" "];
  }
}*/
