// vista en detalle del cliente al seleccionarlo desde la lista, permite editarlo (si se podra pendiente decidir) y editar antes mandar al servidor por primera vez
import { Component, OnInit } from '@angular/core';
import {Cliente} from '../cliente/cliente';
import {BDService} from '../data/bd.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-detalle-cliente',
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.css']
})
export class DetalleClienteComponent implements OnInit {

  cliente: Cliente;
  clientes: Cliente[];

  bdService: BDService;

  private subscripcion: any;
  id: string;
  pendiente: boolean;

  //Contiene imagen por defecto al no pasar imagen por servidor
  private imagen = require("../../assets/images/logolinacentro.jpg");

  constructor(private bdServic: BDService, private route: ActivatedRoute, private router: Router) {
    this.clientes = [];
    this.bdService = bdServic;
    this.cliente = new Cliente();
    this.pendiente = false;
  }

  /*
  * Obtiene los clientes, tantos las obtenidos del servidor como los creados por el usuario que no han sido enviados
   */
  ngOnInit() {
    let self = this;
    this.subscripcion = this.route.params.subscribe(params => {
      this.id = params['id'];

      self.bdService.get('Clientes', this.id, "id_cliente").subscribe(
        (res) => {this.cliente = res},
        (err) => console.log(err),
        () =>  console.log('Done')
      );

      self.bdService.get('ClientesNoEnviadosServ', this.id, "id_cliente").subscribe(
        (res) => {this.pendiente = true},
        (err) => console.log(err),
        () =>  console.log('Done')

      );
    });
  }

  /*
  * Actualiza la entrada de la BD de clientes y añade este a la tabla de clientes no enviados al servidor
   */
  EditarCliente() {
    let self = this;

    const auxPrincipio = this.cliente.codigo_cliente.substr(0, 1);

    if (auxPrincipio === 'N') {
      this.anyadeNoEnviados();
    } else {
        //this.cliente.codigo_cliente = 'M' + this.cliente.codigo_cliente;
      this.anyadeNoEnviados();
      this.cliente.libre1 = '1';
    }


    self.bdService.put('Clientes', this.cliente).subscribe(
      (res) => console.log("Cliente actualizado" + res),
      (err) => console.log("Error " + err),
      () =>  console.log('Done')
    );

    this.router.navigate(['/', 'cliente']);
  }

  /*
  * Añade el cliente a la tabla de clientes no enviados al servidor o lo actualiza en el caso de existir
   */
  anyadeNoEnviados() {
    let self = this;

    if (this.pendiente) {
      self.bdService.put('ClientesNoEnviadosServ', this.cliente).subscribe(
        (res) => console.log("Cliente borrado" + res),
        (err) => console.log("Error " + err),
        () =>  console.log('Done')
      );
    } else {
      self.bdService.post('ClientesNoEnviadosServ', this.cliente).subscribe(
        (res) => console.log("Cliente anyadido" + res),
        (err) => console.log("Error " + err),
        () =>  console.log('Done')
      );
    }
  }

  /*
  * Vuelve a la lista de clientes
   */
  Volver() {
    this.router.navigate(['/', 'cliente']);
  }
}
