// Servicio que gestiona la comunicacion con el servidor y crea identificadores
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { Observable, of} from 'rxjs';
import { catchError, tap} from 'rxjs/operators';
import { Pedido } from '../pedido/pedido';
import {Cliente} from '../cliente/cliente';
import {Articulo} from '../producto/Articulo';
import {Categoria} from '../categoria/categoria';
import {UserService} from '../control/user.service';
import {Comercial} from '../control/Comercial';

/*const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'X-Auth-Token': token
  })
};*/
/*
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};*/
@Injectable({ providedIn: 'root' })
export class ComunicacionService {

 //   configUrl = './config.json';
    clienteUrl = 'https://server.linacentro.com/api/getclientes';
    articuloUrl = 'https://server.linacentro.com/api/getarticulos';
    pedidoUrl = 'https://server.linacentro.com/api/getpedidos';
    categoriaUrl = 'https://server.linacentro.com/api/getcategoriasarticulo';
    confPedidoUrl = 'https://server.linacentro.com/api/finenviopedidos/';

    clientes: Cliente[];

    usuario: Comercial;
    total: number;

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Auth-Token': '0'//this.userService.getUser().token
      })
    };

    /*
    * Inyecta servicios
     */
    constructor(private http: HttpClient, private userService: UserService) {
       this.usuario = new Comercial();
       this.total = 7;
    }

    /*
    * Envia datos al servidor
    * @input peticion: datos a enviar
    * @input destinoUrl: la url a la que se envia la peticion post
     */
  addPeticion(peticion: any, destinoUrl: string): Observable<any> {
    return this.http.post<any>(destinoUrl, peticion, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Auth-Token': this.usuario.token
      })
    })
      .pipe(
        tap((pet) => console.log('added peticion'),
        (er) => console.log('Error en addPeticion' + er))
      );
  }

  /*
  * Pide al servidor los clientes
   */
  getClientes (cod: string): Observable<Cliente[]> {
    return this.http.get<Cliente[]>(this.clienteUrl + '/' + cod, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Auth-Token': this.usuario.token
      })
    })
      .pipe(
        tap(() => console.log('get Clientes' + this.httpOptions.headers),
        () => console.log('Error en getClientes'))
      );
  }

  /*
 * Pide al servidor los articulos
  */
  getArticulos (): Observable<Articulo[]> {
    return this.http.get<Articulo[]>(this.articuloUrl, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Auth-Token': this.usuario.token
      })
    })
      .pipe(
        tap(() => console.log('get Articulos'),
          () => console.log('Error en getArticulos'))
      );
  }

  /*
 * Pide al servidor los pedidos (Actualmente no se llama)
  */
  getPedidos (): Observable<Pedido[]> {
    return this.http.get<Pedido[]>(this.pedidoUrl);
  }

  getConfirmacionPedidos (cantidad: number, comercial: string): Observable<any> {
    return this.http.get<any>(this.confPedidoUrl + comercial + '/' + cantidad)
  .pipe(
      tap(() => console.log('get confirmacion'),
        () => console.log('Error en getConfirmacion'))
    );
  }
  /*
  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': this.usuario.token
      })
    }
   */
/*
 * Pide al servidor las categorias
  */
  getCategorias (): Observable<Categoria[]> {
    return this.http.get<Categoria[]>(this.categoriaUrl, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Auth-Token': this.usuario.token
      })
    })
      .pipe(
        tap(() => console.log('get Categorias'),
        () => console.log('Error en getCategorias'))
      );
  }

  /*
   * Se emplea para crear identificadores obtiene la fecha actual
    */
  anyadeFecha(): string {
    const fecha = new Date().toLocaleString('en-US', {hour12: false}).split(' ');

// Accedemos a la hora en fecha[1] y a la fecha en fecha [0]
    const tiempo = fecha[1];
    const mdy = fecha[0];

    const mdysp = mdy.split('/');
    const mes = parseInt(mdysp[0]);
    const dia = parseInt(mdysp[1]);
    const anyo = parseInt(mdysp[2]);

    const mitiempo = tiempo.split(':');
    const hora = parseInt(mitiempo[0]);
    const minuto = parseInt(mitiempo[1]);
    const segundo = parseInt(mitiempo[2]);

// Creando string
    const formattedDate = '' + dia  + hora + segundo;

    return (formattedDate);
  }

  /*
   * Se emplea para obtener la fecha actual para indicar la ultima actualizacion de la BD
    */
  anyadeFechaBD(): string {
    const fecha = new Date().toLocaleString('en-US', {hour12: false}).split(' ');

// Accedemos a la hora en fecha[1] y a la fecha en fecha [0]
    const tiempo = fecha[1];
    const mdy = fecha[0];

    const mdysp = mdy.split('/');
    const mes = parseInt(mdysp[0]);
    const dia = parseInt(mdysp[1]);
    const anyo = parseInt(mdysp[2]);

    const mitiempo = tiempo.split(':');
    const hora = parseInt(mitiempo[0]);
    const minuto = parseInt(mitiempo[1]);
    const segundo = parseInt(mitiempo[2]);

// Creando string
    const formattedDate = dia + '-' + mes + '-' + anyo + ' ' + hora + ':' + minuto + ':' + segundo;

    console.log(formattedDate);
    return (formattedDate);
  }

  setTotal(total: number) {
    this.total = total;
    console.log('vou' + this.total );
  }
////////////////////////////////////////////////////////////////////////////
// Permite ver mensajes servidor en pantalla (usar en desarrollo)
  ////////////////////////////////////////////////////////////////////////////
  /*
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      //console.error(error); // log to console instead
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add(`ComunicacionService: ${message}`);
  }
*/
}
