// Clase en la que se almacenan los clientes para pasarlos al servidor
export class Pedido {
    codigo_pedido: string;
    fecha_pedido: Date;
    codigo_cliente: string;
    codigo_direccion: string;
    codigo_almacen: string;
    estado: string;
    fecha_entrega: Date;
    suped: string;
    condent: string;
    codigo_expedicion: string;
    observa: string;
    codigo_forma_pago: string;
    bultos: string;
    portes: string;
    codigo_comercial: string;
    comision: string;
    internet: string;
    codigo_divisa: string; // puede ser enum EUR
    valor_divisa: string;
    codigo_transportista: string;
    codigo_tracking: string;
    tportes: string;
    mulagen: string;
    regalo: string;
    descuento_pronto_pago: string;
    descuento_especial: string;
    impreso: string;
    edi: string;
    valor_euros: string;
    iva_portes: string;
    entcuenta: string;
    pendentcu: string;
    total_bruto: string;
    total_neto: string;
    total_pedido: string;
    total_factura: string;
    base_imponible: string;
    total_iva: string;
    total_irpf: string;
    totanti: string;
    totalrec: string;
    calcula: string;
    timestamp: string; // date
    usrtmstmp: string;
    libre1: any;
    libre2: any;
    libre3: any;
    libre4: any;
    libre5: any;
    libre6: any;
    libre7: any;
    libre8: any;
    libre9: any;
    libre10: any;
    resstock: string;
    esti: string;
    codti: string;
    orderti: string;
    statusti: string;
    serie: string;
    detalle: Detalle[];
    comentario: Comentario;
    constructor(codigo_pedido?: string, fecha_pedido?: Date, codigo_cliente?: string, codigo_direccion?: string, codigo_almacen?: string,
        estado?: string, fecha_entrega?: Date, suped?: string, condent?: string, codigo_expedicion?: string, observa?: string,
        codigo_forma_pago?: string, bultos?: string, portes?: string, codigo_comercial?: string, comision?: string, internet?: string,
        codigo_divisa?: string, valor_divisa?: string, codigo_transportista?: string, codigo_tracking?: string, tportes?: string,
        mulagen?: string, regalo?: string, descuento_pronto_pago?: string, descuento_especial?: string, impreso?: string, edi?: string,
        valor_euros?: string, iva_portes?: string, entcuenta?: string, pendentcu?: string, total_bruto?: string, total_neto?: string,
        total_pedido?: string, total_factura?: string, base_imponible?: string, total_iva?: string, total_irpf?: string, totanti?: string,
        totalrec?: string, calcula?: string, timestamp?: string, usrtmstmp?: string, libre1?: any, libre2?: any, libre3?: any, libre4?: any,
        libre5?: any, libre6?: any, libre7?: any, libre8?: any, libre9?: any, libre10?: any, resstock?: string, esti?: string,
        codti?: string, orderti?: string, statusti?: string, serie?: string, detalle?: Detalle[], comentario?: Comentario) {

        this.codigo_pedido = codigo_pedido || '';
        this.fecha_pedido = fecha_pedido || new Date(); // o date
        this.codigo_cliente = codigo_cliente || "";
        this.codigo_direccion = codigo_direccion || "";
        this.codigo_almacen = codigo_almacen || "";
        this.estado = estado || "";
        this.fecha_entrega = fecha_entrega || new Date();
        this.suped = suped || "";
        this.condent = condent || "";
        this.codigo_expedicion = codigo_expedicion || "";
        this.observa = observa || "";
        this.codigo_forma_pago = codigo_forma_pago || "";
        this.bultos = bultos || null;
        this.portes = portes || null;
        this.codigo_comercial = codigo_comercial || "";
        this.comision = comision || null;
        this.internet = internet || "";
        this.codigo_divisa = codigo_divisa || ""; // puede ser enum EUR
        this.valor_divisa = valor_divisa || null;
        this.codigo_transportista = codigo_transportista || "";
        this.codigo_tracking = codigo_tracking || "";
        this.tportes = tportes || "";
        this.mulagen = mulagen || "";
        this.regalo = regalo || "";
        this.descuento_pronto_pago = descuento_pronto_pago || null;
        this.descuento_especial = descuento_especial || null;
        this.impreso = impreso || "";
        this.edi = edi || "";
        this.valor_euros = valor_euros || null;
        this.iva_portes = iva_portes || null;
        this.entcuenta = entcuenta || null;
        this.pendentcu = pendentcu || null;
        this.total_bruto = total_bruto || "0";
        this.total_neto = total_neto || null;
        this.total_pedido = total_pedido || null;
        this.total_factura = total_factura || null;
        this.base_imponible = base_imponible || null;
        this.total_iva = total_iva || null;
        this.total_irpf = total_irpf || null;
        this.totanti = totanti || null;
        this.totalrec = totalrec || null;
        this.calcula = calcula || "";
        this.timestamp = timestamp || null; //date
        this.usrtmstmp = usrtmstmp || "";
        this.libre1 = libre1 || "";
        this.libre2 = libre2 || "";
        this.libre3 = libre3 || "";
        this.libre3 = libre3 || "";
        this.libre4 = libre4 || "";
        this.libre5 = libre5 || "";
        this.libre6 = libre6 || "";
        this.libre7 = libre7 || "";
        this.libre8 = libre8 || "";
        this.libre9 = libre9 || "";
        this.libre10 = libre10 || "";
        this.resstock = resstock || "";
        this.esti = esti || "";
        this.codti = codti || "";
        this.orderti = orderti || "";
        this.statusti = statusti || "";
        this.serie = serie || "";
        this.detalle = detalle || [];
        this.comentario = comentario || null;
    }

    getNombres(): String[] {
        return Object.getOwnPropertyNames(this);
    }
}

export class Detalle {
    codigo_pedido: string;
    codigo_articulo: string;
    descripcion: string;
    cantidad_pedida: string;
    precio: string;
    descuento: string;
    iva: string;
    cantidad_entregada: string;
    unidades: string;
    comision: string;
    servicio: string;
    codigo_comercial: string;
    prop1: string;
    prop2: string;
    linea: string;
    precio_promocion: string;
    descuento_promocion: string;
    modificado: string;
    regalo: string;
    codigo_barras: string;
    descuento_linea: string;
    preivant: string;
    forma_pago: string;
    lote: string;
    fecha_trazabilidad: Date;
    total_linea: string;
    total_bruto: string;
    selección: string;
    libre1: any;
    libre2: any;
    libre3: any;
    libre4: any;
    libre5: any;
    libre6: any;
    libre7: any;
    libre8: any;
    libre9: any;
    libre10: any;

    constructor(codigo_pedido?: string, codigo_articulo?: string, descripcion?: string, cantidad_pedida?: string, precio?: string,
        descuento?: string, iva?: string, cantidad_entregada?: string, unidades?: string, comision?: string, servicio?: string,
        codigo_comercial?: string, prop1?: string, prop2?: string, linea?: string, precio_promocion?: string, descuento_promocion?: string,
        modificado?: string, regalo?: string, codigo_barras?: string, descuento_linea?: string, preivant?: string, forma_pago?: string,
        lote?: string, fecha_trazabilidad?: Date, total_linea?: string, total_bruto?: string, selección?: string, libre1?: any,
        libre2?: any, libre3?: any, libre4?: any, libre5?: any, libre6?: any, libre7?: any, libre8?: any, libre9?: any, libre10?: any) {
        this.codigo_pedido = codigo_pedido || "";
        this.codigo_articulo = codigo_articulo || "";
        this.descripcion = descripcion || "";
        this.cantidad_pedida = cantidad_pedida || null;
        this.precio = precio || "0.000000";
        this.descuento = descuento || "0.000000";
        this.iva = iva || null;
        this.cantidad_entregada = cantidad_entregada || null;
        this.unidades = unidades || "";
        this.comision = comision || null;
        this.servicio = servicio || "";
        this.codigo_comercial = codigo_comercial || "";
        this.prop1 = prop1 || "";
        this.prop2 = prop2 || "";
        this.linea = linea || null;
        this.precio_promocion = precio_promocion || null;
        this.descuento_promocion = descuento_promocion || null;
        this.modificado = modificado || "";
        this.regalo = regalo || "";
        this.codigo_barras = codigo_barras || null;
        this.descuento_linea = descuento_linea || "";
        this.preivant = preivant || null;
        this.forma_pago = forma_pago || "";
        this.lote = lote || "";
        this.fecha_trazabilidad = fecha_trazabilidad || new Date();
        this.total_linea = total_linea || null;
        this.total_bruto = total_bruto || null;
        this.selección = selección || "";
        this.libre1 = libre1 || "";
        this.libre2 = libre2 || "";
        this.libre3 = libre3 || "";
        this.libre4 = libre4 || "";
        this.libre5 = libre5 || "";
        this.libre6 = libre6 || "";
        this.libre7 = libre7 || "";
        this.libre8 = libre8 || "";
        this.libre9 = libre9 || "";
        this.libre10 = libre10 || "";
    }

    getNombres(): String[] {
        return Object.getOwnPropertyNames(this);
    }
}

export class Comentario {
  codigo_pedido: string;
  comentario: string;

  constructor(codigo_pedido?: string, comentario?: string) {
    this.codigo_pedido = codigo_pedido || '';
    this.comentario = comentario || '';
  }

  getNombres(): String[] {
    return Object.getOwnPropertyNames(this);
  }
}
