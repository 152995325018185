// En esta clase se almacenan las categorias pasadas del servidor
export class Categoria {
  id_categoria: string;
  codigo_categoria
  nombre: string;
  predef1: string;
  predef2: string;
  predef3: string;
  predef4: string;
  predef5: string;
  predef6: string;
  predef7: string;
  predef8: string;
  predef9: string;
  predef10: string;
  internet: string;
  codigo_prop1: string;
  codigo_prop2: string;
  actualiza: string;
  fecha_modificacion: Date;
  imagen: string;
  bmptactil: string;
  cantidad_tac: string;
  descripcion_tac: string;
  perigaran: string;
  codigo_codigo_barras: string;
  traza: string;
  lit_lote: string;
  lit_fecha: string;
  pv_iva2010: string;
  pm_iva2010: string;
  imagen_original: string;
  timestamp: Date;
  usrtmstmp: string;
  grupo_contac: string;
  ctacpras: string;
  ctacabon: string;
  grupo_conta: string;
  sctavtas: string;
  sctavabon: string;

  constructor(id_categoria?: string, codigo_categoria?: string, nombre?: string, predef1?: string, predef2?: string, predef3?: string, predef4?: string, predef5?: string, predef6?: string,
              predef7?: string, predef8?: string, predef9?: string, predef10?: string, internet?: string, codigo_prop1?: string, codigo_prop2?: string, actualiza?: string, fecha_modificacion?: Date,
              imagen?: string, bmptactil?: string, cantidad_tac?: string, descripcion_tac?: string, perigaran?: string, codigo_codigo_barras?: string, traza?: string, lit_lote?: string,
              lit_fecha?: string, pv_iva2010?: string, pm_iva2010?: string, imagen_original?: string, timestamp?: Date, usrtmstmp?: string, grupo_contac?: string, ctacpras?: string,
              ctacabon?: string, grupo_conta?: string, sctavtas?: string, sctavabon?: string) {
    this.id_categoria = id_categoria || "0";
    this.codigo_categoria = codigo_categoria || "0";
    this.nombre = nombre || "";
    this.predef1 = predef1 || "";
    this.predef2 = predef2 || "";
    this.predef3 = predef3 || "";
    this.predef4 = predef4 || "";
    this.predef5 = predef5 || "";
    this.predef6 = predef6 || "";
    this.predef7 = predef7 || "";
    this.predef8 = predef8 || "";
    this.predef9 = predef9 || "";
    this.predef10 = predef10 || "";
    this.internet = internet || "F";
    this.codigo_prop1 = codigo_prop1 || "";
    this.codigo_prop2 = codigo_prop2 || "";
    this.actualiza = actualiza || "";
    this.fecha_modificacion = fecha_modificacion || new Date();
    this.imagen = imagen || "";
    this.bmptactil = bmptactil || "";
    this.cantidad_tac = cantidad_tac || "";
    this.descripcion_tac = descripcion_tac || "F";
    this.perigaran = perigaran || "";
    this.codigo_codigo_barras = codigo_codigo_barras || "";
    this.traza = traza || "F";
    this.lit_lote = lit_lote || "";
    this.lit_fecha = lit_fecha || "";
    this.pv_iva2010 = pv_iva2010 || "F";
    this.pm_iva2010 = pm_iva2010 || "F";
    this.imagen_original = codigo_codigo_barras || "";
    this.timestamp = timestamp || new Date();
    this.usrtmstmp = usrtmstmp || "0";
    this.grupo_contac = grupo_contac || "";
    this.ctacpras = ctacpras || "0";
    this.ctacabon = ctacabon || "0";
    this.grupo_conta = grupo_conta || "0";
    this.sctavtas = sctavtas || "0";
    this.sctavabon = sctavabon || "0";
  }
}
