// Crea un nuevo cliente y lo agrega a la BD
import { Component, OnInit } from '@angular/core';
import {Cliente} from '../cliente/cliente';
import {ComunicacionService} from '../comunicacion/comunicacion.service';
import {BDService} from '../data/bd.service';
import {Router} from '@angular/router';
import {Comercial} from '../control/Comercial';
import {FormControl} from '@angular/forms';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-nuevo-cliente',
  templateUrl: './nuevo-cliente.component.html',
  styleUrls: ['./nuevo-cliente.component.css']
})
export class NuevoClienteComponent implements OnInit {

  clientes: Cliente[];
  cliente = new Cliente();
  bdService: BDService;
  ordenadoCliente: Cliente[];

  currentUsers: Comercial[];
  color = '#5BC6E5';
  checkedM = 0;
  checkedReq = 0;
  activate = new FormControl();
  activateMay = new FormControl();

  constructor(private comunicacionService: ComunicacionService, private bdServic: BDService, private router: Router) {
    this.clientes = [];
    this.bdService = bdServic;
    this.bdService.setName('BDPedidos');
  }

  ngOnInit() {
    this.bdService.setName('BDPedidos');
    const self = this;


    // Buscamos la ultima entrada de la tabla Clientes para asignar el identificador
    self.bdService.all('Clientes', 'id_cliente').subscribe(Clientes => {self.clientes = Clientes,
      this.ordenaArray(Clientes); });
  }

  /*
  * Creamos un nuevo cliente y lo agregamos a la BD
   */
  nuevoCliente() {
    let self = this;

    let fecha = this.comunicacionService.anyadeFecha();
    console.log(fecha.length);
    if (fecha.length > 5) {
      fecha = fecha.substring(0, 5);
    }
    this.cliente.codigo_cliente = 'N' + fecha;

    let indice = '0';

    if (this.ordenadoCliente.length > 0) {
      indice = ((Number(this.ordenadoCliente[this.ordenadoCliente.length - 1].id_cliente) + 1).toString());
    }

    this.cliente.id_cliente = indice;

    this.cliente.mayorista = (+this.checkedM).toString();
    this.cliente.req = (this.checkedReq).toString();

    self.bdService.all('Comerciales', 'codigo').subscribe(
      comercial => {
        self.currentUsers = comercial;
        if (self.currentUsers !== undefined) {
          this.cliente.codigo_comercial = comercial[0].codigo,
            this.addClienteBD(this.cliente, 'ClientesNoEnviadosServ');
          this.addClienteBD(this.cliente, 'Clientes');
        }
      });

    this.router.navigate(['/', 'cliente']);
  }

  /*
  * Añadimos el cliente a la BD
  * @input cli: cliente a añadir
  * @input nombre: nombre de la tabla
   */
  addClienteBD(cli: Cliente, nombre: string) {
    let self = this;

   self.bdService.post(nombre, cli).subscribe(
      (res) => console.log("Cliente creado" + res),
      (err) => console.log("Error " + err),
      () =>  console.log('Done')
    );
  }

  /*
  * Ordena un array formado por los clientes de la BD para conocer el id_cliente adecuado para asignarlo al nuevo cliente
  * y evitar repeticiones
   */
  ordenaArray(array: Cliente[]) {
    var ordenado: any[] = [];

    ordenado = array.slice(0);

    ordenado.sort((left, right): number => {
      if(Number(left.id_cliente) < Number(right.id_cliente)) return -1;
      if(Number(left.id_cliente) > Number(right.id_cliente)) return 1;
    });

    this.ordenadoCliente = ordenado;
  }

  ///////////////////////////////////////////////
  // Codigo Toogle
  ///////////////////////////////////////////////

  onChange(ob: MatSlideToggleChange) {
    this.checkedReq = +ob.checked;
  }

  onChangeM(ob: MatSlideToggleChange) {
    this.checkedM = +ob.checked;
  }
}













