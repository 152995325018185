// Muestra los productos en modo catalogo
import { Component, OnInit } from '@angular/core';
import {Articulo} from '../producto/Articulo';
import {BDService} from '../data/bd.service';
import { HostListener } from "@angular/core";
import {ImagenService} from '../imagen/imagen.service';
import {ListaArticulosService} from '../producto/lista-articulos.service';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css']
})
//Aqui se muestran los articulos en vista tipo catalogo
export class CatalogoComponent implements OnInit {

  articulos: Articulo[];
  //artículos mostrados
  aux: Articulo[];
  auxCat: Articulo[];
  categoria: number;
  //Al no encontrar forma iterar por articulos se utiliza como índice
  auxFinal: Articulo[];
  auxValor = 0;
  anchoPantalla: any;
  largoPantalla: any;
  PAN_ACTION = { LEFT: 'panleft', RIGHT: 'panright' };
  /*
* @Input bdService : servicio que gestiona indexedBD localizado en /data
* @Input imagenService :  servicio para tratar con imagenes
 */
  constructor(private bdService: BDService, private imagenService: ImagenService, private listaArticulos: ListaArticulosService) {
    this.auxFinal = [];
    this.auxCat = [];
    this.onResize();
  }

  /*
  * Inicializa los arrays de articulos (articulos, aux) y articulos en categorias (auxCat)
   */
  ngOnInit() {
    let self = this;

    this.bdService.setName('BDPedidos');
    self.listaArticulos.getArticulos().subscribe((resArt) => {self.articulos = resArt,
        self.aux = resArt,
        this.auxCat = resArt,
        this.creaArray(resArt, this.auxValor);
  });
  }

  /*
  * Crea array con articulos pertenecientes a una categoria
  *  @input categoria: identificador categoria
   */
  filtraCategoria(categoria: string) {
    this.categoria = Number(categoria);
    this.auxCat = [];

    if (this.categoria !== -1) {
      for (let entry of this.articulos) {
        if (Number(entry.codigo_categoria) == this.categoria) {
          this.auxCat.push(entry);
        }
      }
    } else {
      this.auxCat = this.articulos;
    }

    this.creaArray(this.auxCat, 0);
  }

  /*
  * Guarda los items que son multipos de valor en el array auxFinal, se emplea para mostrar un numero determinados de articulo por pantalla (3)
  * En pantallas pequeñas muestra todos
   */
  creaArray(arr: Articulo[], valor: number) {
    let i = valor;
    this.auxValor = valor;
    let tamArray = 4;
    if (this.anchoPantalla > 1100) {
      tamArray = 3;
    }
      this.aux = [];
      for (i; i < arr.length && i < this.auxValor + tamArray; i++) {
        this.aux.push(arr[i]);
      }
  }

  /*
  * Evento lanzado al teclear en el input para filtrar articulos
   */
  onKey(event: any) {
    let texto = ((document.getElementById("textprod") as HTMLInputElement).value);
    if (texto === '') {
      this.bdService.all('Articulos', 'descripcion').subscribe((arts) => {this.aux = arts,
        this.auxCat = arts,
        this.creaArray(arts, this.auxValor); });
    } else {
      this.filtrado(texto);
    }
  }

  /*
* Filtra por los elementos que contengan los caracteres
* @input filtro: caracteres pasados
 */
  filtrado(filtrado: string) {
    let auxArr = [];
    const filtro = filtrado.toLowerCase();
    for (let entry of this.auxCat) {
      if ((entry.codigo_articulo).toLowerCase().indexOf(filtro) >= 0 || ((entry.descripcion).toLowerCase().indexOf(filtro) >= 0)) {
        auxArr.push(entry);
      }
    }
    // if (this.anchoPantalla > 1100) {
    // this.auxCat = auxArr;
    this.creaArray(auxArr, this.auxValor);
    /*   } else {
         this.aux = auxArr;
       }*/
  }

  /*
  * Se llama al clicar la flecha izquierda para desplazarse por los articulos
  * @ Input valor: indice del array que contiene todos los articulos que usaremos para saber
  * con que articulos rellenar el array mostrado por pantalla
   */
  scrollIzquierda(valor: number) {
    let tamArray = 4;
    if (this.anchoPantalla > 1100) {
      tamArray = 3;
    }
    if (valor > 0) {
      this.auxValor = valor - tamArray;
      this.creaArray(this.auxCat, this.auxValor);
    }
  }

  /*
  * Se llama al clicar la flecha derecha para desplazarse por los articulos
  * @ Input valor: indice del array que contiene todos los articulos que usaremos para saber
  * con que articulos rellenar el array mostrado por pantalla
   */
  scrollDerecha(valor: number) {
    let tamArray = 4;
    if (this.anchoPantalla > 1100) {
      tamArray = 3;
    }
    if (valor < this.auxCat.length - tamArray) {
      this.auxValor = valor + tamArray;
      this.creaArray(this.auxCat, this.auxValor);
    }
  }

  // action triggered when user pan
  pan(currentIndex: number, action = this.PAN_ACTION.RIGHT) {
    // out of range
    if (currentIndex > this.auxCat.length || currentIndex < 0) { return; }

    // pan right, scroll right
    if (action === this.PAN_ACTION.RIGHT) {
      this.scrollIzquierda(currentIndex);
    }

    // swipe left, scroll left
    if (action === this.PAN_ACTION.LEFT) {
      this.scrollDerecha(currentIndex);
    }
  }

  /*
  * Función activada al cambiar tamaño pantalla, permite modificar el array según si la pantalla esta en modo movil o ordenador
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.largoPantalla = window.innerHeight;
    this.anchoPantalla = window.innerWidth;
    this.creaArray(this.auxCat, this.auxValor);
  }

  /*
  * Llama a servicio para convertir de base64 a blob y mostrar el blob
   */
  meteImagen(datob64: string, tipo: string): string{

    return this.imagenService.meteImagen(datob64, tipo);
  }

  redondeaNumero(num: string, decim: number): string {
    return (Number(num).toFixed(decim));
  }
}
