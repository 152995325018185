// Muestra los articulos por pantalla en formato lista
import { Component, OnInit } from '@angular/core';
import {Articulo} from '../producto/Articulo';
import {BDService} from '../data/bd.service';
import {PedidoService} from '../pedido/pedido.service';

@Component({
  selector: 'app-lista-productos',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.css']
})
export class ListaProductosComponent implements OnInit {
  articulos: Articulo[];
  aux: Articulo[];
  categoria: number;

  page: number;
  pageSize: number;
  unidFormato: boolean;

  constructor(private bdService: BDService, private pedidoService: PedidoService) {
    this.page = 1;
    this.pageSize = 15;
    this.articulos = [];
    this.aux = [];
  }

  ngOnInit() {
    let self = this;

    this.bdService.setName('BDPedidos');
    self.bdService.all('Articulos', 'codigo_categoria', 'descripcion').subscribe(arts => self.articulos = arts);
    self.bdService.all('Articulos', 'codigo_categoria', 'descripcion').subscribe(arts => self.aux = arts);
  }

  /*
 * Crea array con articulos pertenecientes a una categoria
 *  @input categoria: identificador categoria
  */
  filtraCategoria(categoria: string){
    this.categoria = Number(categoria);
    this.aux =[];

    if (this.categoria !== -1) {
      for (let entry of this.articulos) {
        if (Number(entry.codigo_categoria) == this.categoria) {
          this.aux.push(entry);
        }
      }
    }  else {
      this.aux = this.articulos;
    }
  }

  /*
  * Evento lanzado al teclear en el input para filtrar articulos
   */
  onKey(event: any) {
    let texto = ((document.getElementById("textprod") as HTMLInputElement).value);
    if (texto === '') {
      //this.bdService.all('Articulos', 'id_articulo').subscribe(arts => this.aux = arts);
      this.aux = this.articulos;
    } else {
      this.filtrado(texto);
    }
  }

  /*
* Filtra por los elementos que contengan los caracteres
* @input filtro: caracteres pasados
 */
  filtrado(filtrado: string) {
    this.aux = [];
    const filtro = filtrado.toLowerCase();
    for (let entry of this.articulos) {
      if ((entry.codigo_articulo).toLowerCase().indexOf(filtro) >= 0 || ((entry.descripcion).toLowerCase().indexOf(filtro) >= 0)) {
        // console.log("cliente" + entry.id);
        this.aux.push(entry);
      }
    }
  }

  /*
 * Reordena el array que se muestra
 * @input clave: indica por que atributo se ordena
  */
  reordena(clave: string) {
    if (clave === 'codigo') {
      this.aux = this.aux.sort((n1, n2) => Number(n1.precio) - Number(n2.precio));
      this.aux = this.aux.sort((n1, n2) => Number(n1.codigo_articulo) - Number(n2.codigo_articulo));
    }
    if (clave === 'precio') {
      this.aux = this.aux.sort((n1, n2) => Number(n1.precio) - Number(n2.precio));
    }
    if (clave === 'preciomay') {
      this.aux = this.aux.sort((n1, n2) => Number(n1.precio_mayorista) - Number(n2.precio_mayorista));
    }
    if (clave === 'nombre') {
      this.aux = this.aux.sort((n1, n2) => {
        if (n1.descripcion > n2.descripcion) {
          return 1;
        }

        if (n1.descripcion < n2.descripcion) {
          return -1;
        }

        return 0;
      });
    }
    if (clave === 'prom') {
      this.aux = this.aux.sort((n1, n2) => {
        if (n1.predef1 === null ||  n2.predef1 === null) {
          if (n1.predef1 === null &&  n2.predef1 === null) {
            return 0;
          } else if (n1.predef1 === null) {
            return 1;
          } else {
            return -1;
          }
        }

        if (n1.predef1 > n2.predef1) {
          return 1;
        }

        if (n1.predef1 < n2.predef1) {
          return -1;
        }

        return 0;
      });
    }
  }

  redondeaNumero(num: string, decim: number): string {
    return (Number(num).toFixed(decim));
  }
}
