// Clase en la que se almacena el comercial para gestionar login y logout
export class Comercial {
  email: string;
  password: string;
  token: string;
  codigo: string;
  nombre: string;
  comision: string;
  loged: string;

  constructor(codigo?: string, email?: string, password?: string, token?: string, nombre?: string, comision?: string, loged?: string) {
    this.codigo = codigo || '-1';
    this.email = email || '';
    this.password = password || '';
    this.token = token || '';
    this.nombre = nombre || '';
    this.comision = comision || '';
    this.loged = loged || '';
  }
}
