import { Injectable } from '@angular/core';
import {BDService} from './bd.service';
import {ComunicacionService} from '../comunicacion/comunicacion.service';

@Injectable({
  providedIn: 'root'
})
export class CreaBDService {

  constructor(private bdService: BDService, private comunicacionService: ComunicacionService) { }

  creaBD(): boolean {
    let self = this;
    let creado = false;
    const storesSchemaAndSeeds = [
      {
        name: 'Clientes',
        indexes: ['id_cliente', 'codigo_cliente'],
        seeds: []
      },
      {
        name: 'Articulos',
        indexes: ['id_articulo', 'codigo_categoria', 'descripcion', 'codigo_articulo'],
        seeds: []
      },
      {
        name: 'Comerciales',
        indexes: ['codigo'],
        seeds: []
      },
      {
        name: 'Categorias',
        indexes: ['id_categoria', 'codigo_categoria'],
        seeds: []
      },
      {
        name: 'Pedido',
        indexes: ['codigo_pedido'],
        seeds: []
      },
      {
        name: 'ClientesNoEnviadosServ',
        indexes: ['id_cliente', 'codigo_cliente']
      },
      {
        name: 'Fecha',
        seeds: [{id: "1", fecha: 'nunca', fechaPedidos: 'no enviados'}]
      }
    ];

    self.bdService.create(storesSchemaAndSeeds).subscribe(() => {creado = true, this.comunicacionService.setTotal(7); }, () => {creado = false; }, () => {
    });

    return creado;
  }
}
