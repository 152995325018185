import { Injectable } from '@angular/core';
import {Comercial} from './Comercial';
import {Observable} from 'rxjs';
import {BDService} from '../data/bd.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUsers: Comercial[];
  public currentUser: Observable<Comercial>;

  constructor(private bdService: BDService) {
    this.bdService.setName('BDPedidos');
  }
}
