// Muestra todas las categorias en una tabla
import { Component, OnInit } from '@angular/core';
import {BDService} from '../data/bd.service';
import {Categoria} from '../categoria/categoria';

@Component({
  selector: 'app-lista-categorias',
  templateUrl: './lista-categorias.component.html',
  styleUrls: ['./lista-categorias.component.css']
})
export class ListaCategoriasComponent implements OnInit {

  aux: Categoria[];
  bdService: BDService;

  constructor(private bdServic: BDService) {
    this.aux = [];
    this.bdService = bdServic;
  }

  ngOnInit() {
    let self = this;
    this.bdService.setName('BDPedidos');

    self.bdService.all('Categorias', 'codigo_categoria').subscribe(arts => self.aux = arts);
  }

  /*
  * Reordena el array que se muestra
  * @input clave: indica por que atributo se ordena
   */
  reordena(clave: string) {
    if (clave === 'codigo') {
      this.aux = this.aux.sort((n1, n2) => Number(n1.codigo_categoria) - Number(n2.codigo_categoria));
    }
    if (clave === 'categoria') {
      this.aux = this.aux.sort((n1, n2) => {
        if (n1.nombre > n2.nombre) {
          return 1;
        }

        if (n1.nombre < n2.nombre) {
          return -1;
        }

        return 0;
      });
    }
  }
}
