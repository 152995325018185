// Login
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Comercial} from '../control/Comercial';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../control/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {AlertService} from '../alert/alert.service';
import {BDService} from '../data/bd.service';
import { Subscription } from 'rxjs/Subscription';
import {ComunicacionService} from '../comunicacion/comunicacion.service';
import {CreaBDService} from '../data/crea-bd.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private router: Router,
              private route: ActivatedRoute, private alertService: AlertService, private bdService: BDService, private creaBdService: CreaBDService) {
    this.bdService.setName('BDPedidos');
  }

  /*
  * Configuramos el formulario e iniciamos BD
   */

  ngOnInit() {
    let self = this;

    self.creaBdService.creaBD();

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/inicio';
/*
    // Crea la base de datos;
    let self = this;
    this.bdService.setName('BDPedidos');

   // if (!self.authenticationService.databaseCreated) {
      self.bdService.create(storesSchemaAndSeeds).subscribe(
        (out) => {console.log("creando bd"),
                        this.comunicacionService.setTotal(8); },
        (err) => {console.log("error creando bd")},
        () =>(console.log("operacion creando bd terminada")));
      self.authenticationService.databaseCreated = true;
   // }
*/
    this.authenticationService.check();
  }

  /*
  * Getter para acceder mas facilmente a los campos del formulario
   */
  get f() {
    return this.loginForm.controls;
  }

  /*
  * Al pulsar el boton de Login se llama a la funcion correspondiente del servicio de autentificacion, en caso de error se emite mensaje
  * por pantalla
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            this.alertService.error('Email o contraseña incorrectas');
            this.loading = false;
          } else {

          }
        },
        error => {
          this.alertService.error(error.message);
          this.loading = false;
        });
  }

  ngOnDestroy() {
    // acciones de destrucción
    // this.bdSuscription.unsubscribe();
   // this.loginSuscription.unsubscribe();
  }
}
