import {Component, OnInit} from '@angular/core';
import { Cliente } from '../cliente/cliente';
import { BDService } from '../data/bd.service';

import {ComunicacionService} from '../comunicacion/comunicacion.service';
import {Detalle, Pedido} from '../pedido/pedido';
import {ActivatedRoute, Router} from '@angular/router';
import {PedidoService} from './pedido.service';
import {AlertService} from '../alert/alert.service';
import {AuthenticationService} from '../control/authentication.service';
import {el} from '@angular/platform-browser/testing/src/browser_util';
import {FormControl} from '@angular/forms';
import {MatSlideToggleChange} from '@angular/material';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css']
})
export class PedidoComponent implements OnInit {

    pedido: Pedido;
    pedidos: Pedido[];
    cliente: Cliente;

    //almacena los articulos elegidos hasta el momento
    listaArticulos: Detalle[];

    private subscripcion: any;

    bdService: BDService;
    pedidoService: PedidoService;
    id: string;

    descuento: string;
    anotacionesCli: string;

    activate = new FormControl();
    activateV = new FormControl();

    checkedV = false;
    checkedApli = true;

  constructor(private comunicacionService: ComunicacionService, private bdServic: BDService,  private route: ActivatedRoute, private pedidoSer: PedidoService, private alertService: AlertService, private router: Router, private authenticationService: AuthenticationService) {
    this.pedidos = [];
    this.pedido = new Pedido();
    this.bdService = bdServic;
    this.pedidoService = pedidoSer;
    this.descuento = "10.000000";
    this.cliente = new Cliente();
  }

  ngOnInit(){
    let self= this;
    this.subscripcion = this.route.params.subscribe(params => {
      this.id = params['id'];

      self.bdService.get('Clientes', this.id, "id_cliente").subscribe(
        (res) => {this.cliente = res,
          this.anotacionesCli = this.cliente.descripcion_bloqueado;
        this.pedidoService.mayorista = Number(this.cliente.mayorista),
          this.pedidoService.setCliente(this.cliente),
        this.checkV(); },
        (err) => console.log(err),
        () =>  console.log('Done')

      );
    });

    self.bdService.all('Comerciales', 'codigo').subscribe(
      comercial => {
        self.pedidoService.comercial = comercial[0];
      });

    this.listaArticulos = this.pedidoService.getPedidoDetalle();
    this.pedido = this.pedidoService.getPedido();
    this.descuento = '5'; // (document.getElementById('textDescPed') as HTMLInputElement).value;



    if (this.pedidoService.v === 1) {
      /*const miButton = document.getElementById('button-v');

      miButton.style.backgroundColor = 'green';*/
      this.checkedV = true;
    }

    if (Number(this.pedidoService.promocion) === 0) {
      this.checkedApli = false;
    }
  }

  checkV() {
    this.pedidoService.calculaTotal();
  }
  /*
  * Permite modificar la cantidad de producto del input unidades cuando tocas las cajas
  * @input prod: articulo cuya cantidad cambia
   */
  cambiaArticuloCajas(prod: Detalle, indice: string) {
    let valor: string;

    let auxFormato;
    let unidadesCalculadas;
    let aux = 1;
    const auxCajas = Number((document.getElementById('textCajas_' + indice) as HTMLInputElement).value);
    if (auxCajas !== 0 ) { aux = Number(auxCajas);}

    if (Number(prod.servicio) === 0) {
      console.log('codigo' + prod.codigo_articulo);
      this.bdService.get('Articulos', prod.codigo_articulo, 'codigo_articulo').subscribe(
        (res) => {
          auxFormato = this.pedidoService.calculaFormato(res.formato),
            unidadesCalculadas = auxFormato * aux,
            valor = (document.getElementById('textCantPed_' + indice) as HTMLInputElement).value,
            this.esMediaCajas(Number(valor), unidadesCalculadas, indice, auxCajas.toString(), prod, auxFormato);
        },
        (err) => {
        },
        () => {
        }
      );
    } else if (Number(prod.servicio) === 1) {
      valor = (document.getElementById('textCantPed_' + indice) as HTMLInputElement).value = (Number(prod.libre4) * Number(auxCajas)).toString(),
        this.pedidoService.cambiaArticuloCantidad(prod, valor, auxCajas.toString());
    }
  }

  esMediaCajas(unidades: number, unidadesCalculadas: number, indice: string, auxCajas: string, prod: Detalle, formato: number) {
    let resul;
    let op = ((unidades / formato) -  Number(auxCajas));

    if (op < 0) { op =  op * -1; }
    if (Number.isInteger(unidades / formato) || (op > 1) && (Number(auxCajas) !== 0)) {
      resul = unidadesCalculadas.toString();
      (document.getElementById('textCantPed_' + indice) as HTMLInputElement).value = resul;

    } else {
      // no se cambia valor
      resul = unidades;
    }
    this.pedidoService.cambiaArticuloCantidad(prod, resul, auxCajas);

  }
  /*
  * Permite modificar la cantidad de producto del input unidades
  * @input prod: articulo cuya cantidad cambia
   */
  cambiaArticuloUnidades(prod: Detalle, indice: string) {
    let valor: string;
    let cajas: string;
    valor = (document.getElementById('textCantPed_' + indice) as HTMLInputElement).value;

    let auxCajas;
    if (Number(prod.servicio) === 0) {
      cajas = (document.getElementById('textCajas_' + indice) as HTMLInputElement).value;
        this.pedidoService.cambiaArticuloCantidad(prod, valor, cajas);
    } else if (Number(prod.servicio) === 1) {
      cajas = (document.getElementById('textCajas_' + indice) as HTMLInputElement).value = (Math.floor(Number(valor) / Number(prod.libre4))).toString(),
        this.pedidoService.cambiaArticuloCantidad(prod, valor, cajas);
    }
   // (document.getElementById('textCajas_' + indice) as HTMLInputElement).value = valor /
  }

  /*
* Al pulsar que si se cambia el descuento
* @input cambia: etiqueta de la alert empleada para cerrarlo
* @input prod: articulo cuya descuento cambia
*/
  confirm(cambia: any, prod: any, indice: string): void {
    cambia.hide();
  //  this.descuento = prod.value.descuento;
    this.descuento = (document.getElementById('textDescPed_' + indice) as HTMLInputElement).value;

    this.pedidoService.cambiaArticuloDescuento(prod, this.descuento);
  }

  /*
* Al pulsar que no se cambia el descuento
* @input cambia: etiqueta de la alert empleada para cerrarlo
* @input prod: articulo cuya descuento no cambia
*/
  decline(cambia: any, prod: any, indice: string): void {
    cambia.hide();
    //(document.getElementById('textDescPed') as HTMLInputElement).value = prod.descuento;
    (document.getElementById('textDescPed_' + indice) as HTMLInputElement).value = this.pedidoService.redondeaNumero(prod.descuento, 2);
   // this.descuento = this.descuentoAux;
  }

  /*
  * Permite modificar la cantidad de producto del input unidades
  * @input prod: articulo cuya cantidad cambia
   */
  cambiaNotas() {
    this.pedidoService.observa = (document.getElementById('observaciones') as HTMLInputElement).value;
    this.pedidoService.comenta = (document.getElementById('comentario') as HTMLInputElement).value;
  }

  /*
  * Borramos una linea de pedido al pulsar el botón papelera
   */
  eliminarArticulos(prod: any) {
    this.pedidoService.borrarArticulo(prod);
  }

  /*
  * Redondea un numero a 3
   */
  redondeaNumero(num: string): string {
    return (Number(num).toFixed(3));
  }

  adaptaV(num: string): string {
    let aux = num;

    if (this.pedidoService.v === 1) {
      aux = '0';
    }
    return aux;
  }

  /*
  * Guardamos pedido en BD para enviar cuando nos comuniquemos con el servidor
   */
  envia() {
    const auxProm = +this.checkedApli;
    this.pedidoService.promocion = auxProm.toString();
    this.checkedV = true;
    this.pedidoService.v = 1;
    if (this.pedidoService.getPedidoDetalle().length === 0) {
      this.alertService.error('El pedido no contiene articulos');
    } else {
      // this.pedidoService.borralibre1();
      this.pedidoService.anyadePedido();
      this.listaArticulos = [];
      this.borrarPedido();
      this.alertService.success('Pedido añadido correctamente');
      this.ngOnInit();
    }
  }

  /*
  * Borramos el pedido
   */
  botonCancela() {
    document.getElementById('openModalButton').click();
    this.pedidoService.urlFinal = '/pedidoCatalogo/';
}

  /*
  * Borramos el pedido
   */
  cancela() {
    this.listaArticulos = [];
    this.router.navigate(['pedidoCatalogo', this.id]);
    this.borrarPedido();
  }

  /*
  * Eliminamos el pedido
   */
  borrarPedido() {
    this.pedidoService.nuevoPedido = new Pedido();
    this.pedidoService.pedidos = [];
    this.pedidoService.setPedido(new Pedido());
    this.pedidoService.articulosYaPedidos = [];
    this.pedidoService.setEnCurso(false);
    this.pedidoService.totalPedido = 0;
    this.pedidoService.totalBruto = 0;
    this.pedidoService.totalDescuentoCliente = 0;
    this.checkedV = true;
    this.checkedApli = true;
    this.pedidoService.promocion = '1';
    this.pedidoService.v = 1;
    this.pedidoService.totalBrutoSinDescuento = 0;
    this.pedidoService.totalDescuentoArticulos = 0;
    this.pedidoService.totalReq = 0;
    this.pedidoService.comenta = '';
    this.pedidoService.observa = '';
    this.pedidoService.descuento = '0';
    this.pedidoService.setNuevaLinea(1);
    this.ngOnInit();
  }

  /*
  * Cambiamos el color del boton al activar y desactivar y cambiamos el valor de pedidoService.v
   */
  V() {
    const miButton = document.getElementById('button-v');
    console.log(this.pedidoService.v);
    if (this.pedidoService.v === 0) {
      this.pedidoService.v = 1;
      if (this.pedidoService.getPedidoDetalle().length !== 0) {
        this.pedidoService.pedido.libre1 = "1";
      }
        this.pedidoService.aplicaV();
    } else if (this.pedidoService.v === 1) {
      this.pedidoService.v = 0;
      this.pedidoService.pedido.libre1 = "0";
      this.pedidoService.desaplicaV();
    }
  }


  /*////////////////////////////////////////////////////////////////
  // Codigo Para controlar navegacion con un modal
  ////////////////////////////////////////////////////////////////*/

  ModalNavegacion(url: string) {
    this.pedidoService.urlFinal = url;
    if (this.pedidoService.nuevoPedido.codigo_pedido !== '') {
      document.getElementById('openModalButton').click();
    } else {
       this.router.navigate([this.pedidoService.urlFinal]);
    }
  }

  /*
  * Al pulsar sale
* @input cambia: etiqueta de la alert empleada para cerrarlo
*/
  confirma(cambia: any): void {
    document.getElementById('openModalButton').click();
    this.cancela();
    if (this.router.url.indexOf('login') === 0) {
      this.authenticationService.logout();
    }
    if (this.pedidoService.urlFinal.indexOf('pedidoCatalogo') < 0) {
      this.router.navigate([this.pedidoService.urlFinal]);
    }
  }

  /*
 * Al pulsar no sale de la pagina
 * @input cambia: etiqueta de la alert empleada para cerrarlo
 */
  declina(cambia: any): void {
    document.getElementById('openModalButton').click();
  }

  ///////////////////////////////////////////////
  // Codigo Toogle
  ///////////////////////////////////////////////

  onChangePr(ob: MatSlideToggleChange) {
    this.checkedApli = ob.checked;
    const auxProm = +this.checkedApli;
    this.pedidoService.promocion = auxProm.toString();
  }

  onChangeV(ob: MatSlideToggleChange) {
    this.checkedV = ob.checked;
    this.V();
  }
}
