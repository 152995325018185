import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Comercial} from './Comercial';
import {ComunicacionService} from '../comunicacion/comunicacion.service';
import {BDService} from '../data/bd.service';
import {Router} from '@angular/router';
import {UserService} from './user.service';
import {CreaBDService} from '../data/crea-bd.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserAux: any;
  databaseCreated: boolean;

  /*
  * Añade los servicios necesarios y setea nombre BD
   */
 constructor(private http: HttpClient, private comunicacionService: ComunicacionService, private bdService: BDService,  private router: Router, private userService: UserService, private creaBdService: CreaBDService) {
    this.bdService.setName('BDPedidos');
  }

  /*
  * Comprueba si existe algun usario en la BD y almacena en una variable para futuras comprobaciones
   */
  check() {
   let self = this;
    self.bdService.getUltimo('Comerciales', 'codigo').subscribe(
      (res) => {
          this.currentUserAux = res;
      },
      (error1) => console.log('Error usuarios' + error1),
      () => console.log('leido')
    );
  }
  /*
  * Envia datos usuario al servidor y tras ser confirmado como usuario valido lo añade a la BD para mantener la sesion
  * Si no cambia el usuario no cambia el estado de la BD
  * Pero si cambia la resetea
  * @input email: email identificativo de usuario
  * @input pass: contraseña usuario
   */
  login(email: string, pass: string) {
    return this.comunicacionService.addPeticion({ email: email, password: pass },
      'https://server.linacentro.com/api/login')
      .pipe(map(user => {
        // login exitoso
        if (user && user.token) {
          if (this.currentUserAux === 0) { // De llama si no existe BD por lo que no hay comercial
            this.anyadeBd(new Comercial(user.codigo, email, pass, user.token, user.nombre, user.comision, '1'));
          } else {
            if (this.currentUserAux.codigo !== user.codigo) { //no estaba reg(undefined) // se llama al cambiar de comercial
              this.databaseCreated = false;
              this.arreglaBD(user, user.email, user.password);
            } else { // ya estaba logeado
              const self = this;
              self.bdService.put('Comerciales', new Comercial(user.codigo, email, pass, user.token, user.nombre, user.comision, '1')).subscribe(
                (res) => {console.log('Añadido'),
                  this.userService.currentUser = res},
                (err) => console.log(err),
                () =>  this.router.navigate(['/inicio'])
              );
            }
          }
          this.comunicacionService.httpOptions.headers = new HttpHeaders({
            'Content-Type':  'application/json',
            'X-Auth-Token': user.token
          });
        }
        return user;
      }));
  }

  /*
  * Devuelve la BD a su estado inicial y anyade al comercial
  * @input user: datos devueltos del servidor
  * @input email: email identificativo de usuario
  * @input pass: contraseña usuario
   */
  arreglaBD(user: any, email: string, pass: string) {
     this.bdService.setName('BDPedidos');

    const tablas: string[] = ['Clientes', 'Comerciales', 'Fecha', 'Articulos', 'Categorias'];

    this.bdService.borrarTablas(tablas).subscribe(
      (res) => console.log('borrar'),
      (err) => console.log('Error: ' + err),
      () =>  { this.databaseCreated = true,
        this.anyadeBd(new Comercial(user.codigo, email, pass, user.token, user.nombre, user.comision, '1')),
        this.bdService.post('Fecha', {id: '1', fecha: 'nunca'}).subscribe(
          (res) => {console.log('Añadido'); },
          (err) => console.log(err),
          () =>  this.router.navigate(['/inicio'])
        );
      }
    );
  }

  /*
  * Añade usuario a la BD
  * @input comercial: el usuario añadido
   */
  anyadeBd(comercial: Comercial) {
    const self = this;

    self.bdService.post('Comerciales', comercial).subscribe(
      (res) => {console.log('Añadido'),
      this.userService.currentUser = res},
      (err) => console.log(err),
      () =>  this.router.navigate(['/inicio'])
    );
  }

  /*
  * elimina el comercial de la BD
   */
  logout() {
    const self = this;

    self.userService.currentUsers[0].loged = '';

    self.creaBdService.creaBD();

    this.comunicacionService.setTotal(0);
    self.bdService.put('Comerciales', self.userService.currentUsers[0]).subscribe(
      (res) => console.log('Sesion cerrada'),
      (err) => {console.log('salgo sesion'),
                      console.log(err)},
      () =>  {console.log('sali'); }
    );
    this.userService.currentUser = undefined;
  }
}
