/*
/ Clase para mostrar mensajes en pantalla
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {AlertService} from './alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  message: any;

  /*
  * @ Input alertService : muestra mensajes success y error
   */
  constructor(private alertService: AlertService) { }

  /*
  * Recibe el mensaje para mostrarlo pantalla
   */
  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe(message => {
      this.message = message;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
