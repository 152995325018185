import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductoComponent } from './producto/producto.component';
import { PedidoComponent } from './pedido/pedido.component';
import { ClienteComponent } from './cliente/cliente.component';
import { MenuComponent } from './menu/menu.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { CompraComponent } from './compra/compra.component';
import {NuevoClienteComponent} from './nuevo-cliente/nuevo-cliente.component';
import {ListaCategoriasComponent} from './lista-categorias/lista-categorias.component';
import {DetalleClienteComponent} from './detalle-cliente/detalle-cliente.component';
import {DetalleArticuloComponent} from './detalle-articulo/detalle-articulo.component';
import {CatalogoComponent} from './catalogo/catalogo.component';
import {ListaProductosComponent} from './lista-productos/lista-productos.component';
import {ComboPedidoComponent} from './combo-pedido/combo-pedido.component';
import {CatalogoPedidoComponent} from './catalogo-pedido/catalogo-pedido.component';
import {ArticuloPedidoComponent} from './articulo-pedido/articulo-pedido.component';
import {LoginComponent} from './login/login.component';
import {AppComponent} from './app.component';
import {AuthControl} from './control/auth.control';
import {PedidosEditablesComponent} from './pedidos-editables/pedidos-editables.component';

const routes: Routes = [
                        { path: 'inicio', component: MenuComponent, canActivate: [AuthControl]},
                        { path: 'PaginaPorDefecto', component: AppComponent, canActivate: [AuthControl]},
                        { path: 'login', component: LoginComponent },
                        { path: 'categoria', component: CategoriaComponent, canActivate: [AuthControl]},
                        { path: 'categorias', component: ListaCategoriasComponent, canActivate: [AuthControl]},
                        { path: 'producto/:id', component: DetalleArticuloComponent, canActivate: [AuthControl]},
                        { path: 'producto', component: ProductoComponent, canActivate: [AuthControl]},
                        { path: 'productoLista', component: ListaProductosComponent, canActivate: [AuthControl]},
                        { path: 'productoCatalogo', component: CatalogoComponent, canActivate: [AuthControl]},
                        { path: 'cliente', component: ClienteComponent, canActivate: [AuthControl]},
                        { path: 'nuevoCliente', component: NuevoClienteComponent, canActivate: [AuthControl]},
                        { path: 'datosCliente/:id', component: DetalleClienteComponent, canActivate: [AuthControl]},
                        { path: 'pedido', component: PedidoComponent, canActivate: [AuthControl]},
                        { path: 'pedidoLista/:id', component: ComboPedidoComponent, canActivate: [AuthControl]},
                        { path: 'pedidoCatalogo/:id', component: CatalogoPedidoComponent, canActivate: [AuthControl]},
                        { path: 'editaPedido/:id', component: ArticuloPedidoComponent, canActivate: [AuthControl]},
                        {path: 'pedidos', component: PedidosEditablesComponent, canActivate: [AuthControl]},
                        { path: 'compra/:id', component: CompraComponent, canActivate: [AuthControl]},
                        {path: '**', redirectTo: 'login'}
                        ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
