// Realiza aperaciones necesarias pedido y mantiene persistencia entre componentes catalogo y combobox
import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Comentario, Detalle, Pedido} from '../pedido/pedido';
import {Cliente} from '../cliente/cliente';
import {BDService} from '../data/bd.service';
import {Articulo} from '../producto/Articulo';
import {ComunicacionService} from '../comunicacion/comunicacion.service';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {Comercial} from '../control/Comercial';
import {UserService} from '../control/user.service';
import {CreaBDService} from '../data/crea-bd.service';
import {DatePipe} from '@angular/common';
import {PedidoGeneral} from './pedidoGeneral';

const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
         // 'Authorization': 'my-auth-token'
        })
      };

interface Item {
  id: string;
  value: Detalle;
}

interface ItemCompra {
  text: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  pedido: Pedido;
  articulosYaPedidos: Detalle[];


  categoria: number;

  articulos: Articulo[];
  aux: Articulo[];

  // Para BuscaCliente
  bdService: BDService;
  cliente: Cliente;

  enCurso: boolean;
  nuevaLinea: number;

  articuloEstado1: Articulo;

  nuevoPedido = new Pedido();
  iva: string;
  totalPedido: number;
  totalBruto: number;
  precioBrutoConDescuentoArticulo: number;
  precioSinDescuentoArticulo: number;
  pedidoNombre: string;

  totalDescuentoCliente: number;
  precioConDescuento: string;
  precioArticulo: string;
  descuento: string;

  observa: string;
  comenta: string;
  pedidos: Pedido[];

  message: string;
  descuentoAux: string;

  mult = 5;
  auxFinal: Articulo[];

  unidades: number;

  // scroll derecha en grupos de (3)
  auxValor: number;
  auxScroll: Articulo[];

  v: number;

  modificado: number;
  libre1mod: number;

  totalBrutoSinDescuento: number;
  totalDescuentoArticulos: number;

  req: number;
  reqValue: number;
  totalReq: number;
  totalReq14: number;
  totalReq52: number;
  // si 1 se coge el precio de precio mayorista
  mayorista: number;
  formato: number;

  // Controlar comencial
  comercial: Comercial | undefined;

  total: number;
  urlFinal: string;

  descuentoCliente: number;
  porcentDescuentoCliente: number;
  importeIva: number;
  totalIva: number;
  totalIva21: number;
  totalIva10: number;

  promocion: string;

  constructor(private http: HttpClient, private bdServic: BDService, private comunicacionService: ComunicacionService, private router: Router, private creaBdService: CreaBDService, private datePipe: DatePipe) {
    this.articulosYaPedidos = [];
    this.pedido = new Pedido();
    this.bdService = bdServic;
    this.articuloEstado1 = new Articulo();
    this.iva = '21.000000';
    this.totalPedido = 0;
    this.totalBruto = 0;

    this.precioConDescuento = '0.000000';
    this.precioArticulo = '0.000000';
    this.totalDescuentoCliente = 0;
    this.precioSinDescuentoArticulo = 0;

    this.descuento = '0.000000';
    this.cliente = new Cliente();
    this.descuentoCliente = 0;
    this.setEnCurso(false);
    this.setNuevaLinea(1);

    this.observa = '';
    this.comenta = '';
    this.pedidos = [];

    this.descuentoAux = '0.000000';


    this.auxFinal = [this.articuloEstado1];

    this.unidades = 0;
    this.v = 1;

    this.modificado = 0;
    this.libre1mod = 0;

    this.totalBrutoSinDescuento = 0;
    this.precioBrutoConDescuentoArticulo = 0;
    this.totalDescuentoArticulos = 0;

    this.req = 0;
    this.reqValue = 0;
    this.totalReq = 0;

    this.mayorista = 0;
    this.formato = 0;

    this.total = 0.00;
    this.nuevaLinea = 1;

    this.promocion = '1';
    this.inicio();
  }

  inicio() {
    const self = this;
    this.bdService.setName('BDPedidos');

    /*if (this.modificado === 0) {
      this.v = 1;
    */
    if (self.creaBdService.creaBD()) {
      // self.bdService.all('Articulos', 'codigo_categoria', 'descripcion').subscribe(arts => self.articulos = arts);
      self.bdService.all('Articulos', 'codigo_categoria', 'descripcion').subscribe(arts => {
        self.aux = arts,
          this.creaArray(arts, this.mult);
      });
    }
  }

  ///////////////////////////////////////////
  // getters y setters
  ///////////////////////////////////////////

  setPedido(articulo: Pedido) {
    this.pedido = articulo;
  }

  getPedido(): Pedido {
    return this.pedido;
  }

  setPedidosDetalles(articulo: Detalle) {
    this.articulosYaPedidos.push(articulo);
   // this.lista.push({id: id, value: articulo});
  }

  setPedidosDetallesTotales(articulo: Detalle[]) {
    this.articulosYaPedidos = articulo;
    // this.lista.push({id: id, value: articulo});
  }

  getPedidoDetalle(): Detalle[] {
    return this.articulosYaPedidos;
  }

  getEnCurso(): boolean {
    return this.enCurso;
  }

  setEnCurso(curso: boolean) {
    this.enCurso = curso;
  }

  getNuevaLinea(): number {
    return this.nuevaLinea;
  }

  setNuevaLinea(linea: number) {
    this.nuevaLinea = linea;
  }

  setCliente(clien: Cliente) {
    this.cliente = clien;
  }

  setModificado(mod: number) {
    this.modificado = mod;
  }

  borrarArticulo(articulo: any) {
    const pos = this.articulosYaPedidos.indexOf(articulo);

    this.articulosYaPedidos.splice(pos, 1);

    this.calculaTotal();
  }

  setCategoria(cat: number) {
    this.categoria = cat;
  }

  getCategoria() {
    if (this.categoria === undefined) {
      this.categoria = -2;
    }

    return this.categoria;
  }

  /*
  * Calculamos los totales acumulados del pedido
   */
  calculaTotal() {
    const DescuentoArticulos = 0;
    this.totalBrutoSinDescuento = 0;
    this.totalDescuentoArticulos = 0;
    // let total_pedido = 0;
    this.totalIva = 0;
    this.totalIva21 = 0;
    this.totalIva10 = 0;
    this.totalReq = 0;
    this.totalReq14 = 0;
    this.totalReq52 = 0;

    let importeDtoArt;
    let BrutoSinDescuento;
    let unidades;
    let unidadesDiv;
    let cajas = 0;
    this.descuentoCliente = 0;
    this.totalDescuentoCliente = 0;
    this.importeIva = 0;
    this.checkV();
    let req = 0;

    for (const entry of this.articulosYaPedidos) {
      // Obtenemos el precio base
      BrutoSinDescuento = (Number(entry.precio));

      // Obtenemos las unidades y cajas
      unidades = Number(entry.unidades);
      unidadesDiv = Number(entry.unidades);

      if (unidadesDiv === 0)  { unidadesDiv = 1; }
      // Obtenemos el descuento del articulo
      importeDtoArt = BrutoSinDescuento * unidades  * (Number(entry.descuento) / 100);
      this.totalDescuentoArticulos = this.totalDescuentoArticulos + importeDtoArt;
      entry.descuento_promocion = importeDtoArt;

      // Obtenemos el descuento del cliente
      this.calculaDtoCliente(this.porcentDescuentoCliente, BrutoSinDescuento - (importeDtoArt / unidadesDiv), unidades);

      // Obtemos el precio base con los descuentos
      const auxBruTotal = Number(this.redondeaNumero((BrutoSinDescuento * unidades).toString(), 2));
      const auxBru = auxBruTotal - importeDtoArt - this.descuentoCliente;
      entry.total_bruto = auxBruTotal.toString();
      this.totalBrutoSinDescuento = this.totalBrutoSinDescuento + auxBruTotal;
      const auxPed = BrutoSinDescuento - importeDtoArt / unidadesDiv - this.descuentoCliente / unidadesDiv;
      entry.precio_promocion = (BrutoSinDescuento - importeDtoArt / unidadesDiv).toString();
      entry.total_linea = (auxBruTotal - importeDtoArt).toString();
      // total_pedido = total_pedido + (auxPed * unidades);

      // calculamos el iva
      this.importeIva = auxBru * (Number(entry.iva) / 100);

      if (this.v === 1) {
        this.importeIva = 0;
        this.totalIva = 0;
        this.totalIva10 = 0;
        this.totalIva21 = 0;
      } else {
        this.totalIva =  this.totalIva + this.importeIva;
        if (Number(entry.iva) === 21) {
          this.totalIva21 =  this.totalIva21 + this.importeIva;
        } else if (Number(entry.iva) === 10) {
            this.totalIva10 =  this.totalIva10 + this.importeIva;
        }
      }
      entry.preivant = (this.importeIva / unidadesDiv).toString();

      // calculamos recargo equivalencia
      if (Number(entry.servicio) > 0) {
        console.log('serv ' + entry.servicio);
        entry.libre3 = '0';
      } else {
        if (this.v === 1) {
          this.totalReq = 0;
          this.totalReq14 = 0;
          this.totalReq52 = 0;
        } else {
          const valorReq = Number(entry.libre2);
          req = auxBru * (valorReq / 100);
          entry.libre3 = req.toString();
          this.totalReq = this.totalReq + req;

          if (Number(entry.libre2) === 5.2) {
            this.totalReq52 = this.totalReq52 + req;
          } else if (Number(entry.libre2) === 1.4) {
            this.totalReq14 = this.totalReq14 + req;
          }
        }
      }

       cajas = cajas + Number(entry.cantidad_pedida);
    }

    this.nuevoPedido.total_iva = this.redondeaAlAlza(this.totalIva).toString();

    const neto = (this.totalBrutoSinDescuento - this.totalDescuentoArticulos - this.totalDescuentoCliente);
    const auxTotalFactura = this.totalBrutoSinDescuento - this.totalDescuentoArticulos - this.totalDescuentoCliente + this.totalIva + this.totalReq;
    this.nuevoPedido.total_factura = this.redondeaAlAlza(auxTotalFactura).toString();
    this.total = Number(this.nuevoPedido.total_factura);
    this.nuevoPedido.totalrec = this.redondeaNumero(this.totalReq.toString(), 2);

    this.nuevoPedido.total_bruto = this.redondeaAlAlza((this.totalBrutoSinDescuento - this.totalDescuentoArticulos)).toString();
    this.totalBruto = Number(this.nuevoPedido.total_bruto) - this.totalDescuentoCliente;

    this.nuevoPedido.bultos = cajas.toString();
    if (this.v === 1) {
      this.total = Number(this.totalBruto);
       this.nuevoPedido.total_factura = this.nuevoPedido.total_bruto;
      this.nuevoPedido.total_iva = '0.000000';
      this.nuevoPedido.totalrec = '0.000000';
      this.nuevoPedido.iva_portes = '0.000000';
    } else {
      this.nuevoPedido.iva_portes = '21.000000';
    }
    this.nuevoPedido.base_imponible = this.nuevoPedido.total_bruto;
    this.nuevoPedido.total_neto = this.redondeaAlAlza(neto).toString();
    this.nuevoPedido.total_pedido = this.nuevoPedido.total_neto;
  }

  ///////////////////////////////////////////////////////////////////
  // Funciones para calculos auxiliares del valor del pedido
  ///////////////////////////////////////////////////////////////////

  calculaDtoCliente(descuento: number, precioBase: number, unids: number) {
    if (this.v === 0) {
      console.log('v0');
      this.descuentoCliente = precioBase * unids * (descuento / 100);
      this.totalDescuentoCliente = this.totalDescuentoCliente + this.descuentoCliente;
    } else if (this.v === 1) {
      this.porcentDescuentoCliente = 0;
    }
  }

  checkV() {
    if (this.v === 0) {
      this.porcentDescuentoCliente = Number(this.cliente.descuento_pronto_pago);
    } else if (this.v === 1) {
      this.porcentDescuentoCliente = 0;
    }
  }
  //////////////////////////////////////////////////////////////////
  // Tratamiento cambio inputs
  //////////////////////////////////////////////////////////////////

  cambiaArticuloCantidad(articulo: any, valor: string, cajas: string) {
    const pos = this.articulosYaPedidos.indexOf(articulo);

    this.articulosYaPedidos[pos].unidades = valor;
    this.articulosYaPedidos[pos].cantidad_pedida = cajas;

    this.calculaTotal();
  }

  cambiaArticuloDescuento(articulo: any, valor: string) {
    const pos = this.articulosYaPedidos.indexOf(articulo);

     this.articulosYaPedidos[pos].descuento = valor;

    this.calculaTotal();
  }

  //////////////////////////////////////////////
  // Añadir articulo
  /////////////////////////////////////////////

  /*
  * Añade un producto al pedido y si es el primero crea el pedido
  * si V se clica antes de crear el producto pone el campo libre1="1"
  * @input estado: sirve para controlar si es un articulo personalizado, que tendra el campo servicio del detalle de pedido a 1
   */
  anyadeProducto(producto: Articulo, estado: number) {
    const self = this;

    const miCliente = this.cliente;

    if (!this.getEnCurso()) {
      // Buscamos la ultima entrada de la tabla pedidos para asignar el identificador
      this.pedidoNombre = 'NP' + this.comunicacionService.anyadeFecha();

      this.setEnCurso(true);

      let pedV = '';
      if (this.v === 1) {
        pedV = '1';
      }

      const date = new Date();

      if (this.comercial === undefined) {
        self.bdService.all('Comerciales', 'codigo').subscribe((res) => {this.comercial = res[0]; });
        console.log('entro undef');
      }
      this.nuevoPedido = new Pedido(this.pedidoNombre, new Date(), miCliente.codigo_cliente, '0', '001', 'P', date, '', '', '', '', miCliente.codigo_forma_pago, '0', '0', this.comercial.codigo, this.comercial.comision, '',
        miCliente.codigo_divisa, '166.386', miCliente.codigo_transportista, '', '', '', '',
        miCliente.descuento_pronto_pago, miCliente.descuento_especial, '', miCliente.codigo_edi, '1.000000', '0.0', '0.000000', '0.000000', this.totalBruto.toString(), '0.000000', this.totalPedido.toString(), '117.250000', this.totalPedido.toString(), '0.000000', '0.000000', '0.000000', '0.000000', '1', this.datePipe.transform(date, 'yyyy-MM-ddThh:mm:ss').toString(), '00', pedV, '', '', '', '', '', '', '', '', '', '', '', '', '0', '', '', []);

     /* let pedGen = new PedidoGeneral(this.pedidoNombre, new Date(), miCliente.codigo_cliente, "0", "", miCliente.codigo_forma_pago, "0", "20", this.comercial.comision,
        miCliente.codigo_divisa, "166.386", miCliente.codigo_transportista,
        miCliente.descuento_pronto_pago, miCliente.descuento_especial, miCliente.codigo_edi,  this.totalBruto.toString(), "0.000000", this.totalPedido.toString(), "", this.totalPedido.toString(), "0.000000", "0.000000", "0.000000", "0.000000", "1", this.datePipe.transform(date, 'yyyy-MM-dd').toString(), "00", pedV, "", "", "", "", "", "", "", "", "", "", "", "", "0", "", "", []);
*/

      if (producto.tipo_iva.match('G')) {
        this.iva = '21.000000';
      } else if (producto.tipo_iva.match('R')) {
        this.iva = '10.000000';
      }

      this.setPedido(this.nuevoPedido);

      if (this.cliente.req === '1') {
        this.req = 1;
      }
    }
    this.anyadeLinea(producto, estado);
  }

  /*
  * Añade un producto al pedido
  * totalfactura contiene precio total pedido es el bruto al que se se aplica descuento cliente (ya tiene el del articulo)
  * y luego se le anyade iva y en caso de existir recargo equivalencia
   */
  anyadeLinea(producto: Articulo, estado: number) {

    let serv = '0';
    let lib1 = '';

    // Se almacena el precio del articulo si es mayorista o no
    let precioArticuloVarMayorista = 0;

    const hayReq = Number(this.cliente.req);

    this.unidades = Number(producto.unidades);

    if (producto.predef2 !== '') {
      this.descuento = producto.predef2;
      this.descuentoAux = producto.predef2;
    }

    if (this.mayorista === 0) {
      precioArticuloVarMayorista = producto.precio;
    } else {
      precioArticuloVarMayorista = producto.precio_mayorista;
    }

    if (producto.tipo_iva.match('G')) {
      this.iva = '21.000000';
      if (hayReq === 1) {
        this.req = 5.2;
      }
    } else if (producto.tipo_iva.match('R')) {
      this.iva = '10.000000';
      if (hayReq === 1) {
        this.req = 1.4;
      }
    }

    if (hayReq === 0) {
      this.req = 0;
    }

    if ( this.req !== 0) {
      this.reqValue = ((Number(this.precioConDescuento) * Number(this.req)) / 100);
    }

    let precioLinea = ((Number(this.precioConDescuento) * (( Number(this.iva) / 100) + 1)) + this.reqValue).toString();

    if (this.v === 1) {
      precioLinea = (Number(precioArticuloVarMayorista)).toString();
      lib1 = '1';
    }

    // meter recargo equivalencia

    if (this.v === 1) {
      this.reqValue = 0;
    }

    if (precioArticuloVarMayorista === null) {
      precioArticuloVarMayorista = 0.000000;
    }

    let cajas;

    if (estado !== 0) {
      this.reqValue = 0;
      serv = '1';
      this.formato = Number(producto.unidades) / estado;
    }

    if (this.unidades === 0) {
      console.log('sin unids');
      return;
    } else {
      const aux = Math.floor(this.unidades / this.formato);
      cajas = aux.toString();
    }

    const nuevaLineaAux = this.getNuevaLinea();

    const nuevaLineaPedido = new Detalle(this.pedidoNombre, producto.codigo_articulo, producto.descripcion, cajas,
      precioArticuloVarMayorista.toString(), this.descuento.toString(), this.iva.toString(), '0', producto.unidades,
      this.comercial.comision, serv, this.comercial.codigo, '', '', nuevaLineaAux.toString(), '', '', '', '' ,
      producto.codigo_barras, this.descuento.toString(), '0.000000' , this.cliente.codigo_forma_pago,  producto.lote,
      producto.fecha_trazabilidad, precioLinea, '', '', lib1, this.req.toString(), this.reqValue.toString(), this.formato,
      '', '', '', '', '', '');

    this.setPedidosDetalles(nuevaLineaPedido);

    if (estado === 0) {
      this.setNuevaLinea(nuevaLineaAux + 1);
    }

    if (this.v === 1) {
      this.nuevoPedido.total_iva = '0.000000';
    }
    this.setPedido(this.nuevoPedido);

    this.calculaTotal();
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  // Anyadir pedido
  /////////////////////////////////////////////////////////////////////////////////////////////

  anyadePedido() {
    const self = this;
    this.arreglaDetalle();
    this.nuevoPedido.detalle = this.getPedidoDetalle();
    this.nuevoPedido.observa = this.observa;
    this.nuevoPedido.libre4 = this.promocion;
    this.nuevoPedido.comentario = new Comentario(this.nuevoPedido.codigo_pedido, this.comenta);
    this.nuevoPedido.codigo_cliente = this.cliente.codigo_cliente;
    this.nuevoPedido.codigo_direccion = this.cliente.libre2;
    this.pedidos.push(this.nuevoPedido);

    if (this.modificado === 0) {
      self.bdService.post('Pedido', this.nuevoPedido).subscribe(
        (res) => {
          console.log('Pedido creado');
        },
        (err) => console.log(err),
        () => console.log('Done')
      );

      this.setPedido(this.nuevoPedido);
    } else if (this.modificado === 1) {
        self.bdService.put('Pedido', this.nuevoPedido).subscribe(
          (res) => {
            console.log('Pedido modificado');
          },
          (err) => console.log(err),
          () => console.log('Done')
        );
      }

    this.setModificado(1);
    this.setNuevaLinea(1);
    console.log(JSON.stringify(this.pedidos));
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Controla si los efectos de V estan aplicados o no
  ///////////////////////////////////////////////////////////////////////////////////

  /*
  * Si V no esta aplicado en algun articulo pedido lo aplica en este y all el pedido
   */
  aplicaV() {
    for (const entry of this.articulosYaPedidos) {
      if (entry.libre1 === '') {
        entry.libre1 = '1';
        entry.libre3 = '0';
      }
    }
    this.calculaTotal();
  }

  /*
  * Si V esta aplicado en algun articulo pedido lo devuelve a su estado original y a all el pedido
   */
  desaplicaV() {
    for (const entry of this.articulosYaPedidos) {
      if (entry.libre1 === '1' || this.libre1mod === 1) {
        entry.libre1 = '';
        entry.libre3 = '';
      }
    }
    this.calculaTotal();
  }

  borralibre1() {
    for (const entry of this.articulosYaPedidos) {
      if (entry.libre1 === '1') {
        entry.libre1 = '';
      }
    }
  }

  /*/////////////////////////////////////////////////////////////////////////////////////
  // Adapta el detalle en caso de ser necesario
   ///////////////////////////////////////////////////////////////////////////////////*/

  arreglaDetalle() {
    this.nuevoPedido.detalle = this.getPedidoDetalle();
    let serv = 1;
    for (const entry of this.nuevoPedido.detalle) {
      if (Number(entry.servicio) > 0) {
        entry.servicio = serv.toString();
        entry.unidades = '1.000000';
        entry.linea = '1';
        serv ++;
      }
    }
  }
  //////////////////////////////////////////////////////////////////////////////////////
  // crea array para mostrar catalogo
  ///////////////////////////////////////////////////////////////////////////////////////
  // Guarda los items que son multipos de mult en el array auxFinal
  creaArray(arr: Articulo[], mult: number) {
    let i = 0;
    for (i; i < arr.length; i++) {
      if (i % mult === 0) {
        this.auxFinal.push(arr[i]);
      }
    }
  }


  /*
  * Encuentra el cliente con su identificador
   */
  buscaCliente(codigo: string): Cliente {
    const self = this;

    // this.bdService.setName('BDPedidos');
    if (codigo === '' || codigo === undefined) {
      this.cliente = new Cliente();
      this.cliente.nombre = 'Error, cliente no definido';
    } else {
      self.bdService.get('Clientes', codigo, 'id_cliente').subscribe(
        (res) => {this.cliente = res; },
        (err) => console.log(err),
        () =>  console.log('Done')
      );
    }
    return this.cliente;
  }

  /*
  * Redondea decimales al valor que se indique
   */
  redondeaNumero(num: string, decim: number): string {
    return (Number(num).toFixed(decim));
  }

  /*
  * Redondea decimales al valor que se indique
   */
  redondeaAlAlza(num: number): number {
    const alza = Math.ceil(num * 100);
    return (alza / 100);
  }


  /////////////////////////////////////////////////////////////////////////////////////////
  // Comprueba si el formato del pedido es normal o especial
  /////////////////////////////////////////////////////////////////////////////////////////
  checkFormato(formato: string): boolean {
    if (formato === 'DNA.') {
      // this.unidades = 12;
      this.formato = 12;
      return true;
    }
    if (formato === 'ROLLO') {
      // this.unidades = 1;
      this.formato = 1;
      return true;
    }
    this.formato = Number(formato);
    return false;
  }
  calculaFormato(formato: string): number {
    if (formato === 'DNA.') {
      this.unidades = 12;
      this.formato = 12;
    } else if (formato === 'ROLLO') {
      this.unidades = 1;
      this.formato = 1;
    } else if (Number(formato) === 0) {
      this.unidades = 1;
      this.formato = 1;
    } else {
      this.formato = Number(formato);
    }
    return(this.formato);
  }

  calculaPorcentageIva(letra: string) {
    if (letra.match('G')) {
      this.iva = '21.000000';
      if (this.req !== 0) {
        this.req = 5.2;
      }
    } else if (letra.match('R')) {
      this.iva = '10.000000';
      if (this.req !== 0) {
        this.req = 1.4;
      }
    }
  }
}
