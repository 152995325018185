// Clase en la que se almacenan los productos pasados del servidor
export class Articulo {
  id_articulo: string;
  codigo_articulo: string;
  descripcion: string;
  codigo_categoria: string;
  formato: string;
  pzorep: string;
  coste_promocion: string;
  coste_divisa: string;
  stock_minimo: string;
  stock_maximo: string;
  tipo_iva: string;
  grupo_contable: string;
  precio: number;
  precio_mayorista: number;
  descuento1: string;
  descuento2: number;
  descuento3: number;
  descuento4: number;
  descuento5: number;
  descuento6: number;
  pendiente_servir: number;
  pendiente_recibir: number;
  codigo_divisa: string;
  entacu: string;
  codigo_proveedor: string;
  referencia_proveedor: string;
  selected: string;
  unidades: string;
  etiquetas: string;
  imagen: string;
  predef1: string;
  predef2: string;
  predef3: string;
  predef4: string;
  predef5: string;
  predef6: string;
  predef7: string;
  predef8: string;
  predef9: string;
  predef10: string;
  internet: string;
  unidad: string;
  codigo_barras: string;
  tipo_codigo: string;
 kit: string;
 peso: string;
 tara: string;
 beneficio_pvp: string;
 beneficio_mayorista: string;
 precprop1: string;
 precprop2: string;
 precio_con_iva: string;
 precio_mayorista_con_iva: string;
 control_stock: string;
 prepv: string;
 actualizar: string;
 fecha_actualizado: string;
 grupo_contable_c: string;
 salacu: string;
 subcuenta_ventas: string;
 subcuenta_compras: string;
 anticipo: string;
 codigo_forma_pago: string;
 subcuenta_vabon: string;
 subcuenta_cabon: string;
 bmptactil: string;
 cantidad_tac: string;
 des_tac: string;
 perigaran: string;
 entalm: string;
 fecha_entalm: string;
  lote: string;
  fecha_trazabilidad: Date;
  imagen_orifginal: string;
  timestamp: Date;
  usrtmstmp: string;
  imagen_b64: any;
  tipo_b64: any;

  constructor(id_articulo?: string, codigo_articulo?: string, descripcion?: string, codigo_categoria?: string, formato?: string, pzorep?: string, coste_promocion?: string,
              coste_divisa?: string, stock_minimo?: string, stock_maximo?: string, tipo_iva?: string, grupo_contable?: string, precio?: number, precio_mayorista?: number,
    descuento1?: string, descuento2?: number, descuento3?: number, descuento4?: number, descuento5?: number, descuento6?: number, pendiente_servir?: number, pendiente_recibir?: number,
              codigo_divisa?: string, entacu?: string, codigo_proveedor?: string, referencia_proveedor?: string, selected?: string, unidades?: string, etiquetas?: string, imagen?: string, predef1?: string,
              predef2?: string, predef3?: string, predef4?: string, predef5?: string, predef6?: string, predef7?: string, predef8?: string, predef9?: string, predef10?: string, internet?: string,
              unidad?: string, codigo_barras?: string, tipo_codigo?: string, kit?: string, peso?: string, tara?: string, beneficio_pvp?: string, beneficio_mayorista?: string, precprop1?: string,
              precprop2?: string, precio_con_iva?: string, precio_mayorista_con_iva?: string, control_stock?: string, prepv?: string, actualizar?: string, fecha_actualizado?: string,
              grupo_contable_c?: string, salacu?: string, subcuenta_ventas?: string, subcuenta_compras?: string, anticipo?: string, codigo_forma_pago?: string, subcuenta_vabon?: string,
              subcuenta_cabon?: string, bmptactil?: string, cantidad_tac?: string, des_tac?: string, perigaran?: string, entalm?: string, fecha_entalm?: string, lote?: string, fecha_trazabilidad?: Date,
              imagen_orifginal?: string, timestamp?: Date, usrtmstmp?: string, imagen_b64?: any, tipo_b64?: any) {

    this.id_articulo = id_articulo || '-1';
    this.codigo_articulo = codigo_articulo || '';
    this.descripcion = descripcion || 'CAMBIA TEXTO ART. PERSONALIZADO';
    this.codigo_categoria = codigo_categoria || "";
    this.formato = formato || '1';
    this.pzorep = pzorep || "";
    this.coste_promocion = coste_promocion || "";
    this.coste_divisa = coste_divisa || "";
    this.stock_minimo = stock_minimo || "";
    this.stock_maximo = stock_maximo || "";
    this.tipo_iva = tipo_iva || 'G';
    this.grupo_contable = grupo_contable || "";
    this.precio = precio || 0.000000;
    this.precio_mayorista = precio_mayorista || 0;
    this.descuento1 = descuento1 || "10.000000";
    this.descuento2 = descuento2 || 0;
    this.descuento3 = descuento3 || 0;
    this.descuento4 = descuento4 || 0;
    this.descuento5 = descuento5 || 0;
    this.descuento6 = descuento6 || 0;
    this.pendiente_servir = pendiente_servir || 0;
    this.pendiente_recibir = pendiente_recibir || 0;
    this.codigo_divisa = codigo_divisa || "";
    this.entacu = entacu || "";
    this.codigo_proveedor = codigo_proveedor || "";
    this.referencia_proveedor = referencia_proveedor || "";
    this.selected = selected || "";
    this.unidades = unidades || '0.000000';
    this.etiquetas = etiquetas || "";
    this.imagen = imagen || "";
    this.predef1 = predef1 || "";
    this.predef2 = predef2 || "";
    this.predef3 = predef3 || "";
    this.predef4 = predef4 || "";
    this.predef5 = predef5 || "";
    this.predef6 = predef6 || "";
    this.predef7 = predef7 || "";
    this.predef8 = predef8 || "";
    this.predef9 = predef9 || "";
    this.predef10 = predef10 || "";
    this.internet = internet || "";
    this.unidad = unidad || "";
    this.codigo_barras = codigo_barras || "";
    this.tipo_codigo = tipo_codigo || "";
    this.kit = kit || "";
    this.peso = peso || "";
    this.tara = tara || "";
    this.beneficio_pvp = beneficio_pvp || "";
    this.beneficio_mayorista = beneficio_mayorista || "";
    this.precprop1 = precprop1 || "";
    this.precprop2 = precprop2 || "";
    this.precio_con_iva = precio_con_iva || "";
    this.precio_mayorista_con_iva = precio_mayorista_con_iva || "";
    this.control_stock = control_stock || "";
    this.prepv = prepv || "";
    this.actualizar = actualizar || "";
    this.fecha_actualizado = fecha_actualizado || "";
    this.grupo_contable_c = grupo_contable_c || "";
    this.salacu = salacu || "";
    this.subcuenta_ventas = subcuenta_ventas || "";
    this.subcuenta_compras = subcuenta_compras || "";
    this.anticipo = anticipo || "";
    this.codigo_forma_pago = codigo_forma_pago || "";
    this.subcuenta_vabon = subcuenta_vabon || "";
    this.subcuenta_cabon = subcuenta_cabon || "";
    this.bmptactil = bmptactil || "";
    this.cantidad_tac = cantidad_tac || "";
    this.des_tac = des_tac || "";
    this.perigaran = perigaran || "";
    this.entalm = entalm || "";
    this.fecha_entalm = predef5 || "";
    this.lote = lote || "";
    this.fecha_trazabilidad = fecha_trazabilidad || new Date();
    this.imagen_orifginal = imagen_orifginal || "";
    this.timestamp = timestamp || new Date();
    this.usrtmstmp = usrtmstmp || "";
    this.imagen_b64 = imagen_b64 || "";
    this.tipo_b64 = tipo_b64 || "";
  }
}
