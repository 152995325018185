import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Cliente} from '../cliente/cliente';
import {Pedido} from '../pedido/pedido';
import {BDService} from '../data/bd.service';
import {PedidoService} from '../pedido/pedido.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

interface pedCli{
  ped: Pedido,
  clien: Cliente,
}

@Component({
  selector: 'app-pedidos-editables',
  templateUrl: './pedidos-editables.component.html',
  styleUrls: ['./pedidos-editables.component.css']
})
export class PedidosEditablesComponent implements OnInit {

  cliente: Cliente;
  aux: Cliente[];

  pedidos: Pedido[];
  auxPedidos: Pedido[];
  // databaseCreated: boolean;
  bdService: BDService;
  pedidoService: PedidoService;
  arrayItem: pedCli[];
  totalBrutoSinDescuento: number[];
  totalDescuentoArticulos: number[];
  totalConDescuento: number[];
  @ViewChild('myModal') myModal;

  idPedido: string;

  ensenyaDetalle: Boolean[];

  // pedidosDefs: Pedido[];

  // Inyectamos el servicio que contiene DAO
  constructor(private bdServic: BDService, private router: Router, private pedidoSer: PedidoService) {
    this.cliente = new Cliente();
    this.aux = [];
    this.bdService = bdServic;
    this.pedidoService = pedidoSer;
    this.arrayItem = [];
    this.totalBrutoSinDescuento = [];
    this.totalDescuentoArticulos = [];
    this.totalConDescuento = [];
    this.ensenyaDetalle = [];
  }

  ngOnInit() {
    // Create the IndexedDB database and perform few operations.
    let self = this;
    self.bdService.setName('BDPedidos');

    self.bdService.all('Clientes', 'codigo_cliente').subscribe(Clientes => self.aux = Clientes);

   // self.bdService.all('Pedido', 'codigo_pedido').subscribe(Pedidos => self.pedidosDefs = Pedidos);
    self.bdService.all('Pedido', 'codigo_pedido').subscribe(Pedidos => self.pedidos = Pedidos);
    self.bdService.all('Pedido', 'codigo_pedido').subscribe(Pedidos => {self.auxPedidos = Pedidos,
      this.muestraPantalla(Pedidos); });
  }

  /*
  * Almacena en el array que se mostrará por pantalla los pedidos con su correspondiente cliente
  * @input pedidos: array con todos los pedidos de la BD
   */
  private muestraPantalla(pedidos: Pedido[]) {
    this.totalBrutoSinDescuento = [];
    this.totalDescuentoArticulos = [];
    this.totalConDescuento = [];

    for (let entry of pedidos) {
      let self = this;
      self.bdService.get('Clientes', entry.codigo_cliente, "codigo_cliente").subscribe(
        (res) => {this.cliente = res,
          this.arrayItem.push({ped: entry, clien: this.cliente}),
          this.ensenyaDetalle.push(false),
          this.calculaTotal(entry); },
        (err) => console.log(err),
        () =>  console.log('Done')
      );
    }
  }

  private calculaTotal(pedido: Pedido) {

    let BrutoSinDescuento = 0;
    let DescuentoArticulos = 0;

    for (const entry of pedido.detalle) {
      BrutoSinDescuento = BrutoSinDescuento + (Number(entry.precio) * Number(entry.unidades));
      DescuentoArticulos = DescuentoArticulos + Number(entry.descuento_promocion);
    }
    this.totalBrutoSinDescuento.push(BrutoSinDescuento);
    this.totalDescuentoArticulos.push(DescuentoArticulos);
    this.totalConDescuento.push(BrutoSinDescuento - DescuentoArticulos);
  }

  /*
 * Se prepara el pedido para modificar y navega a CatalogoPedido para modificarlo realmente alli
  */
  envia(codigo: string) {
    let self = this;
    let pedido = new Pedido();
    this.pedidoService.setModificado(1);
    self.bdService.get('Pedido', codigo, 'codigo_pedido').subscribe(
      (res) => {
            pedido = res;
            this.pedidoService.setPedido(pedido),
              this.pedidoService.nuevoPedido = pedido;
              this.pedidoService.pedidoNombre = res.codigo_pedido,
              this.pedidoService.comenta = res.comentario.comentario,
              this.pedidoService.observa = res.observa,
              this.pedidoService.setEnCurso(true),
                this.V(res),
                this.pedidoService.v = Number(pedido.libre1),
               this.pedidoService.setPedidosDetallesTotales(res.detalle),
                self.bdService.get('Clientes', res.codigo_cliente, 'codigo_cliente').subscribe(
                  (resc) => {
                    this.cliente = resc,
                    this.router.navigate(['/compra', this.cliente.id_cliente]);
            },
            (err) => console.log(err),
            () => console.log('Done')
          );
      }
    );
  }

  /*
  * Se llama al modificar el pedido para configurar todos los atributos del modo V en pedidoService
   */
  V(res: Pedido) {
    if (res.libre1 === '1') {
      this.pedidoService.v = 1;
      this.pedidoService.libre1mod = 1;
    } else {
      this.pedidoService.v = 0;
    }
  }

  /*
  * Se llama al seleccionar en el modal si a borrar el pedido.
  * Borra el pedido en cuestion
   */
  borra(codigo: string) {
    let self = this;

    self.bdService.remove('Pedido', codigo).subscribe(
      (res) => console.log('pedido borrado'),
      (err) => console.log(err),
      () => console.log(window.location.reload())
    );
  }

  /*
  * Evento lanzado al teclear en el input para filtrar clientes
   */
  onKey(event: any) {
    let texto = ((document.getElementById("textclien") as HTMLInputElement).value);
    this.arrayItem = [];
    if (texto === '') {
       this.muestraPantalla(this.pedidos);
    } else {
      this.filtrado(texto);
    }
  }

  /*
  * Filtra por los elementos que contengan los caracteres
  * @input filtro: caracteres pasados
   */
  filtrado(filtrado: string) {
    const filtro = filtrado.toLowerCase();
    for (let entry of this.pedidos) {
      let self = this;
      self.bdService.get('Clientes', entry.codigo_cliente, "codigo_cliente").subscribe(
        (res) => {this.cliente = res,
          this.funcionFiltrado(this.cliente, filtro, entry); },
        (err) => console.log(err),
        () =>  console.log('Done')
      );
    }
  }

  /*
  * En esta función se realiza realmente el filtrado con el cliente y el pedido obtenido en filtrado()
   */
  funcionFiltrado(cliente: Cliente, filtro: string, entry: Pedido) {
    const reg = new RegExp(filtro);
      if (((cliente.codigo_cliente).toLowerCase().indexOf(filtro) >= 0) || ((cliente.nombre).toLowerCase().indexOf(filtro) >= 0) || ((cliente.direccion).toLowerCase().indexOf(filtro) >= 0) ||
        ((cliente.poblacion).toLowerCase().indexOf(filtro) >= 0) || ((cliente.cpostal).toLowerCase().indexOf(filtro) >= 0)) {
        this.arrayItem.push({ped: entry, clien: this.cliente});
      }
    }

  /*
  * Abre el modal para confirmar el borrado
   */
  borraPedidos(id: string) {
    document.getElementById('openModalButton').click();
    console.log(id);
    this.idPedido = id;
  }

  private cambiaDetalle(indice: number) {
    this.ensenyaDetalle[indice] = !this.ensenyaDetalle[indice];
  }
}
