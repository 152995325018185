import { Component, OnInit, OnDestroy } from '@angular/core';
import { Cliente } from "../cliente/cliente";
import { BDService } from "../data/bd.service";
import {ActivatedRoute, Router} from '@angular/router';
import {PedidoService} from '../pedido/pedido.service';

@Component({
  selector: 'app-compra',
  templateUrl: './compra.component.html',
  styleUrls: ['./compra.component.css']
})
export class CompraComponent implements OnInit, OnDestroy {

  cliente: Cliente;

  bdService: BDService;

  private subscripcion: any;

  id: number;

  /*
  * Inicializamos los datos que emplearán el combobox y el catalogo
   */
  constructor(private bdServic: BDService, private router: Router, private route: ActivatedRoute, private pedidoService: PedidoService) {
    this.bdService = bdServic;
    this.pedidoService.auxValor = 0;
    this.pedidoService.auxScroll = [];
  }

  /*
  * Tomamos el id de la url para poder navegar a pedidoCatalogo y cargamos ese componente por defecto ademas de pasar el cliente
   */
  ngOnInit() {
    this.bdService.setName('BDPedidos');
    const self = this;

    this.subscripcion = this.route.params.subscribe(params => {
      this.id = +params['id'];

      self.bdService.get('Clientes', this.id.toString(), 'id_cliente').subscribe(
        (res) => {this.cliente = res},
        (err) => console.log(err),
        () =>  console.log('Done')

      );
    });

    this.router.navigate(['pedidoCatalogo', this.id]);
  }

  /*
  *Pulsando el boton con el icono de lista navegamos al componente combo-pedido
   */
  ComboPedido() {
    this.router.navigate(['pedidoLista', this.id]);
  }

  /*
 *Pulsando el boton correspondiente navegamos al componente catalogo-pedido
  */
  CatalogoPedido() {
    this.router.navigate(['pedidoCatalogo', this.id]);
  }

  ngOnDestroy() {
      this.subscripcion.unsubscribe();
  }
}
