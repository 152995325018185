import {Injectable, OnInit} from '@angular/core';
import {Articulo} from './Articulo';
import {BDService} from '../data/bd.service';
import {ImagenService} from '../imagen/imagen.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListaArticulosService {

  totalArticulos: Articulo[];


  constructor(private bdService: BDService, private imagenService: ImagenService) {
    let self = this;
    console.log('cons list');
      self.bdService.all('Articulos', 'codigo_categoria').subscribe(arts => {
        self.ordena(arts);
      },
        (err) => console.log(err),
        () => {console.log('ordenando' + self.totalArticulos.length);
          if (self.imagenService.mapa.size === 0 ) {
            self.imagenService.creaMapa(self.totalArticulos);
          }});
  }

  getArticulos(): Observable<Articulo[]> {
    let self = this;
    return Observable.create((observer) => {
      if (self.totalArticulos === undefined) {
        self.bdService.all('Articulos', 'codigo_categoria').subscribe(arts => {
          self.ordena(arts);
        },
          () => {},
          () => {observer.next(self.totalArticulos.slice()),
          observer.complete(); });
      } else {
        observer.next(self.totalArticulos.slice()),
          observer.complete();
      }
    });
  }

  ordena(articulos: Articulo[]) {
    console.log('ordena');
    let self = this;
    self.totalArticulos = articulos.sort((n1, n2) => {
      return Number(n1.codigo_categoria) - Number(n2.codigo_categoria) || self.descripcion(n1.descripcion, n2.descripcion);
    });
  }

  descripcion (d1: String, d2: string) {
    if (d1 > d2) {
      return 1;
    }

    if (d1 < d2) {
      return -1;
    }

    return 0;
  }

}
