// Comprueba cada vez que se navega a una pagina si se esta logeado y si no lo esta, navega al login
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Comercial} from './Comercial';
import {BDService} from '../data/bd.service';
import { map } from 'rxjs/operators';
import {UserService} from './user.service';
import {ComunicacionService} from '../comunicacion/comunicacion.service';
import {CreaBDService} from '../data/crea-bd.service';

@Injectable({ providedIn: 'root' })
export class AuthControl implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private bdService: BDService,
    private userService: UserService,
    private comunicacionService: ComunicacionService,
    private creaBdService: CreaBDService
  ) {}

  /*
  *  Comprueba si hay un comercial en la BD y en caso contrario navega al login
  */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let self = this;

    self.creaBdService.creaBD();

    return self.bdService.all('Comerciales', 'codigo')
      .pipe(map(comercial => {
        self.userService.currentUsers = comercial;
        if (self.userService.currentUsers !== undefined) {
          self.userService.currentUser = comercial[0];
          if (self.userService.currentUser && comercial[0].loged === '1') {
            // existe el usuario asi que devuelve true*/
            return true;
          } else {
            this.comunicacionService.setTotal(0);
            this.router.navigate(['/', 'login']);
            return false;
          }
        }
      }));
  }
}
