/*
* Muestra menú aplicación y en caso de no existir BD crea la estructura
 */
import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BDService} from './data/bd.service';
import {Comercial} from './control/Comercial';
import {AuthenticationService} from './control/authentication.service';
import {UserService} from './control/user.service';
import {Subscription} from 'rxjs';
import {PedidoComponent} from './pedido/pedido.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  contador: number;
  sub1: number;
  sub2: number;

  anchoPantalla: any;
  largoPantalla: any;

  currentUsers: Comercial[];
  currentUser: Comercial;

  loginSuscription: Subscription;
  // databaseCreated: boolean;

  /*
  *Comprueba si el comercial esta logeado
  * @Input router: controla navegacion entre paginas
  * @Input bdService : servicio que gestiona indexedBD localizado en /data
  * @Input authenticacion service: servicio de gestion de sesion con login y logout en /control
   */
  constructor(private router: Router, private bdService: BDService, private authenticationService: AuthenticationService, private userService: UserService, private pedidoC: PedidoComponent) {
    this.bdService.setName('BDPedidos');
    let self = this;


    if (self.authenticationService.databaseCreated) {
      this.loginSuscription = self.bdService.all('Comerciales', 'codigo').subscribe(
        comercial => {
          self.currentUsers = comercial;
          if (self.currentUsers !== undefined) {
            self.currentUser = comercial[0];
          }
        });
     }
  }

  /*
  * En caso de no existir BD crea el esquema
   */
  ngOnInit() {
    this.contador = 1;
    this.sub1 = 1;
    this.sub2 = 1;
  }

  /////////////////////////////////////////////////////////////////////////
  // Métodos para menú en modo móvil (<1101 px ancho pantalla)
  /////////////////////////////////////////////////////////////////////////

  /*
  * Para abrir/ cerrar el menu en el modo móvil
 */
  abreMenu() {
    let myNav = document.getElementById('menuNav');

      if (this.contador == 1) {
        myNav.style.left = '0';
      this.contador = 0;
    } else {
        myNav.style.left = '-100%';
        this.contador = 1;
     // (document.getElementById('menuNav') as HTMLInputElement).left = '-100%';
    }
  }

  /*
  * Abre submenu Clientes
   */
  abreCliente() {

    this.onResize();

    if (this.anchoPantalla < 1101) {
      let myNav = document.getElementById('submenu1');

      if (this.sub1 === 1) {
        myNav.style.display = 'block';
        this.sub1 = 0;
      } else if (this.sub1 === 0) {
        myNav.style.display = 'none';
        this.sub1 = 1;
      }
    }
  }

  /*
  * Abre submenu Articulos
   */
    abreArticulo() {
      this.onResize();

      if (this.anchoPantalla < 1101) {
        let myNav = document.getElementById('submenu2');

        if (this.sub2 === 1) {
          myNav.style.display = 'block';
          this.sub2 = 0;
        } else {
          myNav.style.display = 'none';
          this.sub2 = 1;
        }
      }
    }

    //////////////////////////////////////////////////
  // Redimensionado pantalla
  ///////////////////////////////////////////////////

  /*
  * Función activada al cambiar tamaño pantalla
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    // Este metodo sirve para que si en modo móvil se selecciona algún submenú al pasar a modo ordenador se siga el submenú al pasar el ratón por encima
    if (this.anchoPantalla < 1001 && window.innerWidth > 1000) {
      window.location.reload();
    }

    this.largoPantalla = window.innerHeight;
    this.anchoPantalla = window.innerWidth;
  }

  ///////////////////////////////////////////////////////////////////////////////////
  // Navegación entre componentes
  //////////////////////////////////////////////////////////////////////////////////

  cambiaCliente() {
    this.router.navigate(['/', 'cliente']);
  /*  console.log(this.router.url);
    if ((this.router.url.indexOf('pedidoCatalogo') < 0) &&  (this.router.url.indexOf('pedidoLista'))) {
      console.log('voy');
      this.router.navigate(['/', 'cliente']);
    } else {
      this.pedidoC.ModalNavegacion('/cliente');
    }*/
  }

  nuevoCli() {
    this.revisaPedido('nuevoCliente', this.router.url);
  }

  cambiaCategorias() {
    this.revisaPedido('categorias', this.router.url);
  }

  cambiaProducto(bdService: BDService) {
    this.revisaPedido('productoLista', this.router.url);
  }

  cambiaCatalogo(bdService: BDService) {
    this.revisaPedido('productoCatalogo', this.router.url);
  }

  MuestraPedidos(bdService: BDService) {
    this.revisaPedido('pedidos', this.router.url);
  }

  home(bdService: BDService) {
    this.revisaPedido('inicio', this.router.url);
  }

  /*
  * Borra al comercial de la base de datos y vuelve a la pantalla login
   */
  salir() {
    if ((this.router.url.indexOf('pedidoCatalogo') < 0) &&  (this.router.url.indexOf('pedidoLista'))) {
      this.authenticationService.logout();
      this.router.navigate(['/', 'login']);
    } else {
      this.pedidoC.ModalNavegacion('/login');
    }
  }

  /*
  * Controla si inicia la navegacion desde un pedido para poder lanzar el modal o no
  * @input url : url destino
  * @ input urlOrigen : url origen
   */
  revisaPedido(url: string, urlOrigen: string) {
    if ((urlOrigen.indexOf('pedidoCatalogo') < 0) &&  (urlOrigen.indexOf('pedidoLista'))) {
      this.router.navigate(['/', url]);
    } else {
      this.pedidoC.ModalNavegacion('/' + url);
    }
  }

  ngOnDestroy() {
    // acciones de destrucción
    this.loginSuscription.unsubscribe();
  }
}
