import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ReactiveFormsModule } from '@angular/forms';
import {ProgressBarModule} from 'angular-progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { ProductoComponent } from './producto/producto.component';
import { PedidoComponent } from './pedido/pedido.component';
import { ClienteComponent } from './cliente/cliente.component';
import { MenuComponent } from './menu/menu.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { CompraComponent } from './compra/compra.component';
import { MessagesComponent } from './messages/messages.component';
import { NuevoClienteComponent } from './nuevo-cliente/nuevo-cliente.component';

import { BDService } from './data/bd.service';
import { ComunicacionService } from './comunicacion/comunicacion.service';
import { PedidoService } from './pedido/pedido.service';
import { MessageService } from './messages/message.service';
import { ListaCategoriasComponent } from './lista-categorias/lista-categorias.component';
import { DetalleClienteComponent } from './detalle-cliente/detalle-cliente.component';
import { DetalleArticuloComponent } from './detalle-articulo/detalle-articulo.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { ListaProductosComponent } from './lista-productos/lista-productos.component';
import { ComboPedidoComponent } from './combo-pedido/combo-pedido.component';
import { CatalogoPedidoComponent } from './catalogo-pedido/catalogo-pedido.component';
import { ArticuloPedidoComponent } from './articulo-pedido/articulo-pedido.component';
import { LoginComponent } from './login/login.component';
import { AlertComponent } from './alert/alert.component';
import {AuthenticationService} from './control/authentication.service';
import {AuthControl} from './control/auth.control';
import {ImagenService} from './imagen/imagen.service';
import {UserService} from './control/user.service';
import { PedidosEditablesComponent } from './pedidos-editables/pedidos-editables.component';
import {CreaBDService} from './data/crea-bd.service';
import {DatePipe} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    ProductoComponent,
    PedidoComponent,
    ClienteComponent,
    MenuComponent,
    CategoriaComponent,
    CompraComponent,
    NuevoClienteComponent,
    MessagesComponent,
    ListaCategoriasComponent,
    DetalleClienteComponent,
    DetalleArticuloComponent,
    CatalogoComponent,
    ListaProductosComponent,
    ComboPedidoComponent,
    CatalogoPedidoComponent,
    ArticuloPedidoComponent,
    LoginComponent,
    AlertComponent,
    PedidosEditablesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DropDownsModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgbModule.forRoot(),
    ProgressBarModule,
    MatSlideToggleModule
  ],
  providers: [
              ComunicacionService,
              BDService,
              PedidoService,
              MessageService,
              AuthenticationService,
              AuthControl,
              ImagenService,
              UserService,
              CreaBDService,
              PedidoComponent,
              DatePipe
             ],
  bootstrap: [AppComponent]
})
export class AppModule { }
