import { Component, OnInit } from '@angular/core';
import { BDService } from '../data/bd.service';
import { Router } from '@angular/router';
import {ComunicacionService} from '../comunicacion/comunicacion.service';

import {Cliente} from '../cliente/cliente';
import { Pedido } from '../pedido/pedido';
import {Categoria} from '../categoria/categoria';
import {Comercial} from '../control/Comercial';
import {UserService} from '../control/user.service';
import {forEach} from '@angular/router/src/utils/collection';
import {ListaArticulosService} from '../producto/lista-articulos.service';
import {ImagenService} from '../imagen/imagen.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit{

  // databaseCreated: boolean;
  BDcliente = [new Cliente()];
  BDarticulo = [];
  BDcategoria = [new Categoria()];
  clientesAEnviar = [new Cliente()];
  pedidosAEnviar = [new Pedido()];
  url = 'https://server.linacentro.com/api/';

  currentUsers: Comercial[];

  fecha: string;
  fechaP: string
  loading = false;
  terminados: number;
  total: number;
  progreso: number;
  porcentaje: number;
  constructor(private bdService: BDService, private router: Router, private comunicacionService: ComunicacionService,
              private userService: UserService, private listaArticulos: ListaArticulosService) {
    if(this.comunicacionService.total === 0) {
      this.comunicacionService.setTotal(7);
    }
    this.total = this.comunicacionService.total;
    this.progreso = 0;
    this.porcentaje = 100 / this.total;
  }

  /*
  * Comprueba si estamos logeados y setea el campo de usuario de authenticationService
  * Obtiene la ultima fecha de modificacion de la BD
   */
  ngOnInit() {
    this.bdService.setName('BDPedidos');
    let self = this;
    self.bdService.all('Comerciales', 'codigo').subscribe(
      comercial => {
        self.currentUsers = comercial;
        if (self.currentUsers !== undefined) {
          self.userService.currentUser = comercial[0];
        }
      });

    self.bdService.get('Fecha', '1', ' ').subscribe(
      fecha => {
        self.fecha = fecha.fecha;
        self.fechaP = fecha.fechaPedidos;
      });

    this.listaArticulos.getArticulos();
    this.terminados = 0;
  }

  /*
  * Al presionar el botón SINCRONIZAR hace las operaciones get, post al servidor
   */
    enviaDatos(): void {
      this.comunicacionService.usuario = this.currentUsers[0];
      this.loading = true;
      this.progreso = 0;
      this.postDatos('ClientesNoEnviadosServ', 'id_cliente', this.clientesAEnviar, this.url + 'updateclientes')
      // this.postDatos('Pedido', 'codigo_pedido', this.fixPedidos(this.pedidosAEnviar), this.url+'updatepedidos')
      this.getDatos();
    }

  /*
* Al presionar el botón ENVIAR PEDIDOS hace  post de pedidos al servidor
 */
  enviaPedidos(): void {
    const self = this;
    this.comunicacionService.usuario = this.currentUsers[0];
    this.loading = true;
    const fec = this.comunicacionService.anyadeFechaBD();
    let cantPedidos = 0;
    self.bdService.all('Pedido', 'codigo_pedido').subscribe(
      Datos => {
        this.pedidosAEnviar = Datos,
          this.comunicacionService.addPeticion(this.fixPedidos(this.pedidosAEnviar), this.url + 'updatepedidos').subscribe(
            (res) => {
              self.bdService.borrarTabla('Pedido').subscribe(
                (res2) => {this.progreso = 100,
                  this.actualizaFechaPedidos(); },
                (err) => console.log('Error: ' + err),
                () => {cantPedidos = this.pedidosAEnviar.length,
                  this.comunicacionService.getConfirmacionPedidos(cantPedidos, this.currentUsers[0].codigo).subscribe(
                    (resC) => console.log(resC),
                    (errC) => console.log(errC)
                  ); }
              );
            },
            (err) => console.log(err),
            () => {
              this.getNuevosClientes(),
              this.loading = false; }
          );
      }
    );
  }

  fixPedidos(ped: Pedido[]): Pedido[] {
    let pedIva;

    for (const pedi of ped) {
      if (Number(Number(pedi.total_iva) === 0)) {
        pedIva = false;
        pedi.descuento_pronto_pago = '0.000000';
      } else { pedIva  = true; }

      // pedi.libre1 = "";
      for (const entry of pedi.detalle) {
        if (Number(entry.servicio) > 0) {
          entry.cantidad_pedida = '1.000000';
          entry.descuento = '0.000000';
        }
        if (pedIva) {
          entry.iva = '0.0';
        }
         const auxC = entry.unidades;
        // entry.libre1 = "";
        entry.libre2 = "";
        entry.libre3 = "";
        entry.libre4 = "";
        entry.preivant = '0.000000';
         entry.unidades = entry.cantidad_pedida;
         entry.cantidad_pedida = auxC;
      }
    }

    return ped;
  }

  getNuevosClientes() {
    let nuevosClientes;
    let self = this;

    self.bdService.all('ClientesNoEnviadosServ', 'codigo_cliente').subscribe(
      Datos => {
        nuevosClientes = Datos,
        this.borraNuevosClientesConPedidos(nuevosClientes); },
      (err) => console.log(err),
      () => {}
    );
  }

  borraNuevosClientesConPedidos(nuevosClientes: Cliente[]) {
    let nuevosClientesPedidos = [];
    let self = this;

    for (const entry of this.pedidosAEnviar) {
      if (nuevosClientesPedidos.indexOf(entry.codigo_cliente) === -1 && nuevosClientes.length > 0) {
        nuevosClientesPedidos.push(entry.codigo_cliente);

        for (const entry2 of nuevosClientes) {
          if (entry2.codigo_cliente === entry.codigo_cliente) {

            nuevosClientes.splice(nuevosClientes.indexOf(entry2, 0), 1);

            this.comunicacionService.addPeticion([entry2], this.url + 'updateclientes').subscribe(
              (res) => {
                self.bdService.remove('ClientesNoEnviadosServ', entry2.id_cliente).subscribe(
                  (res) => console.log("Hecho borrado de cliente"),
                  (err) => console.log("Error: " + err),
                  () => console.log("hecho post datos")
                );
              }
            );
          }
        }
      }
    }
  }
    /*
    *Obtiene los datos del servidor
     */
    getDatos(): void {
      this.getArticulos();
      this.getClientes();
     // this.getPedidos();
      this.getCategorias();
    }

    /*
    * Obtiene clientes del servidor e introduce datos en nuesta BD
     */
    getClientes(): void {
    let self = this;

      this.comunicacionService.getClientes(this.currentUsers[0].codigo)
        .subscribe(datos => {this.BDcliente = datos ,
          self.bdService.borrarTabla('Clientes').subscribe(
            (res) => {this.actualizaBD('Clientes', this.controlaMultientry(this.BDcliente)); },
            (err) => console.log("Error: " + err),
            () => { this.terminados = this.terminados + 1,
              this.progreso = this.progreso + this.porcentaje,
              this.controlaLoading(); }
          ); });

    }

    controlaMultientry(entrada: Cliente[]): Cliente[] {
      let i = 0;
      for (let entry of entrada) {
        entry.id_cliente = i.toString();
        i += 1;
      }

      return entrada;
    }
  /*
  * Obtiene articulos del servidor e introduce datos en nuesta BD
   */
  getArticulos(): void {
    let self = this;

    this.comunicacionService.getArticulos()
      .subscribe(datos => {this.BDarticulo = datos,
        self.bdService.borrarTabla('Articulos').subscribe(
          (res) => {this.actualizaBD('Articulos', this.BDarticulo); },
          (err) => console.log("Error: " + err),
          () => { this.terminados = this.terminados + 1,
            this.progreso = this.progreso + this.porcentaje,
            this.controlaLoading(); }
        ); });
  }

  /*getPedidos(): void {
    this.comunicacionService.getPedidos()
      .subscribe(datos => {this.BDpedido = datos,
        this.actualizaBD('Pedido', this.BDpedido)});
  }*/

  /*
    * Obtiene categorias del servidor e introduce datos en nuesta BD
     */
  getCategorias(): void {
    let self = this;

    this.comunicacionService.getCategorias()
      .subscribe(datos => {this.BDcategoria = datos ,
        self.bdService.borrarTabla('Categorias').subscribe(
          (res) => {this.actualizaBD('Categorias', this.BDcategoria)},
          (err) => console.log("Error: " + err),
          () => { this.terminados = this.terminados + 1,
            this.progreso = this.progreso + this.porcentaje,
            this.controlaLoading(); }
        ); });
  }

  /*
  * Envia ya sea clientes o pedidos nuevos al servidor
   */
  postDatos(nombre: string, indice: string, datos: any[], url: string) {
    let self = this;

    self.bdService.all(nombre, indice).subscribe(
      Datos => {
        datos = Datos,
          this.comunicacionService.addPeticion(datos, url).subscribe(
            (res) => {
              self.bdService.borrarTabla(nombre).subscribe(
                (res) => console.log("Hecho"),
                (err) => console.log("Error: " + err),
                () => console.log("hecho post datos")
              );
            },
            (err) => console.log(err),
            () => { this.terminados = this.terminados + 1,
              this.progreso = this.progreso + this.porcentaje,
              this.controlaLoading(); }
          );
      }
    );
  }

  /*
  * Actualiza nuestra BD
   */
  actualizaBD(nombre: string, datos: any[]): void {
    let self = this;
    let i = 0;
    const tam = datos.length;
    console.log("tam tabla: " + tam);
    for (let entry of datos) {
      self.bdService.post(nombre, entry).subscribe(
        (res) =>  {}/*console.log('Hecho actualizaBD' + nombre)*/,
        (err) => console.log('Error BD: ' + err),
        () =>  { i = i + 1,
        this.transaccionCompleta(tam, i),
          this.controlaLoading(); }
      );
    }
  }

  /*
  * Actualiza fecha de modificacion de BD
   */
  actualizaFecha() {
    let self = this;
    const fec = this.comunicacionService.anyadeFechaBD();
    this.fecha = fec;
    /*let dato = [];
    dato.push({id: "1", fecha: fec});*/

    self.bdService.put('Fecha', {id: "1", fecha: fec, fechaPedidos: this.fechaP}).subscribe(
      (res) => {console.log('Añadido')},
      (err) => console.log(err),
      () =>  { this.terminados = 0; }
    );
  }

  /*
  * Actualiza fecha de modificacion de BD
   */
  actualizaFechaPedidos() {
    const self = this;
    const fec = this.comunicacionService.anyadeFechaBD();
    this.fechaP = fec;

    self.bdService.put('Fecha', {id: "1", fecha: this.fecha, fechaPedidos: fec}).subscribe(
      (res) => {console.log('Añadido')},
      (err) => console.log(err),
      () =>  { this.terminados = 0; }
    );
  }

  /*
  * Comprueba si ha terminado la actualizacion de la base de datos con el servidor, en caso positivo actualiza la fecha de actualización de la BD
   */
  controlaLoading() {
    console.log('voy por ' + this.terminados + ' ' + this.total);
    if (this.terminados === this.total) {// 7
      // this.comunicacionService.setTotal(8);
      this.loading = false;
      console.log('ter ' + this.terminados);
      this.terminados = 0;
      this.actualizaFecha();
    }
  }

  /*
  * Controla que haya terminado de actualizar la tabla de indexDB
   */
  transaccionCompleta(tam: number, i: number) {
    if (tam === i) {
      console.log('transaccion hecha ' + this.terminados);
      this.terminados = this.terminados + 1;
      this.progreso = this.progreso + this.porcentaje;
    }
  }
}
