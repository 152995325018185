// Permite navegar entre las formas de visualizar articulos lista y catalogo
import { Component, OnInit } from '@angular/core';
import { Articulo } from './Articulo';
import { BDService } from '../data/bd.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css']
})
export class ProductoComponent implements OnInit {
    articulos: Articulo[];
    aux: Articulo[];
    categoria: number;

  constructor(private bdService: BDService, private router: Router ) { }

  ngOnInit() {
      let self = this;

      this.bdService.setName('BDPedidos');
      self.bdService.all('Articulos', 'codigo_categoria', 'descripcion').subscribe(arts => self.articulos = arts);
      self.bdService.all('Articulos', 'codigo_categoria', 'descripcion').subscribe(arts => self.aux = arts);
  }

  /*
 *Pulsando el boton con el icono de lista navegamos al componente lista-producto
  */
  Lista() {
    this.router.navigate(['/', 'productoLista']);
  }

  /*
*Pulsando el boton correspondiente navegamos al componente catalogo
*/
  Catalogo() {
    this.router.navigate(['/', 'productoCatalogo']);
  }
}
