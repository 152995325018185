import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BDService} from '../data/bd.service';
import {PedidoService} from '../pedido/pedido.service';
import {Cliente} from '../cliente/cliente';
import {Articulo} from '../producto/Articulo';
import { HostListener } from '@angular/core';
import {ImagenService} from '../imagen/imagen.service';
import {Subscription} from 'rxjs';
import {forEach} from '@angular/router/src/utils/collection';
import {ListaArticulosService} from '../producto/lista-articulos.service';

interface Item {
  text: string,
  value: string,
}

@Component({
  selector: 'app-catalogo-pedido',
  templateUrl: './catalogo-pedido.component.html',
  styleUrls: ['./catalogo-pedido.component.css']
})
export class CatalogoPedidoComponent implements OnInit {

  cliente: Cliente;

  articulos: Articulo[];

  id: number;
  private subscripcion: any;

  bdService: BDService;
  pedidoService: PedidoService;

  private imagen = require("../../assets/images/logolinacentro.jpg");
 categoria: number;

  //controlar redimension pantalla
  anchoPantalla: any;
  largoPantalla: any;

  cajas: number;

  mayorista: boolean;

  PAN_ACTION = { LEFT: 'panleft', RIGHT: 'panright' };

  /*
  * @Input bdService : servicio que gestiona indexedBD localizado en /data
  * @Input pedidoService Realiza operaciones necesarias para realizar pedidos y mantiene persistencia de datos al navegar entre componentes
  * @Input router: controla navegacion entre paginas
  * @Input route: obtiene parametros de la url
  * @Input imagenService :  servicio para tratar con imagenes
 */
  constructor(private bdServic: BDService, private pedidoSer: PedidoService, private router: Router, private route: ActivatedRoute, private imagenService: ImagenService,
              private listaArticulos: ListaArticulosService) {
    this.bdService = bdServic;
    this.pedidoService = pedidoSer;
    this.onResize();
    if (this.pedidoService.auxValor === undefined) {
      this.pedidoService.auxValor = 0;
    }
    this.cajas = 1;
  }

  /*
  * Inicializa los arrays de articulos
  * Obtiene cliente de la url
 */
  ngOnInit() {
    const self = this;
    this.bdService.setName('BDPedidos');

    // Se añade articulo especial para poder poner lineas de pedido con el estado = 1
    const art = new Articulo();

    self.listaArticulos.getArticulos().subscribe((resArt) => {self.articulos = resArt; },
      (error1) => console.log(error1),
      () => {self.articulos.unshift(art),
        this.pedidoService.auxFinal = self.articulos,
        this.pedidoService.auxScroll = [];
        this.creaArray(self.articulos, this.pedidoService.auxValor); });

    this.subscripcion = this.route.params.subscribe(params => {
      this.id = +params['id'];

      self.bdService.get('Clientes', this.id.toString(), "id_cliente").subscribe(
        (res) => {this.cliente = res,
          this.pedidoService.setCliente(res),
        this.asignaMayorista(); },
        (err) => console.log(err),
        () =>  console.log('Done')

      );
    });

    this.categoria = -1;
  }

  /*
 * Crea array con articulos pertenecientes a una categoria
 *  @input categoria: identificador categoria
  */
  filtraCategoria(categoria: string, recarga?: number) {
    this.categoria = Number(categoria);

    // this.pedidoService.aux = [];
    // this.pedidoService.auxFinal = [];
    let auxC = [];
    // this.pedidoService.aux.push(new Articulo());

    if (this.categoria !== -1) {
      for (let entry of this.articulos) {
        if (Number(entry.codigo_categoria) === this.categoria) {
          // this.pedidoService.aux.push(entry);
          auxC.push(entry);
        }
      }
      this.pedidoService.auxFinal = auxC;
    } else {
      // this.pedidoService.aux = this.articulos;
      this.pedidoService.auxFinal = this.articulos;
    }
    let texto = ((document.getElementById("textprod") as HTMLInputElement).value);
    this.creaArray(this.pedidoService.auxFinal, 0);

    if (texto !== '' && recarga === undefined) {
      (document.getElementById("textprod") as HTMLInputElement).value = '';
    }
  }

  asignaMayorista() {
    if (Number(this.cliente.mayorista) === 0) {
      this.mayorista = false;
    } else if (Number(this.cliente.mayorista) === 1) {
      this.mayorista = true;
    }
  }
  /*
  * Se llama al clicar una imagen del catalogo y navega para pedir el articulo con mas detalles
   */
  anyade(articulo: Articulo) {
    this.pedidoService.descuento = '0';
    if (articulo.predef2 !== "") {
      this.pedidoService.descuento = articulo.predef2;
    }
    this.router.navigate(['editaPedido', articulo.id_articulo]);
  }

  /*
  * Se llama al cliclar el botón para navergar a la otra vista de pedido
   */
  ComboPedido(bdService: BDService) {
    this.router.navigate(['pedidoLista', this.id]);
  }

  /*
* Llama a eligeProducto de pedido service pasandole un articulo de la BD o un articulo especial que se crea al introducir descripcion personalizada
* @input prod: id_articulo, identificador para encontrarlo en la BD o si es -1 saber que es en el Detalle del pedido el estado será "1"
 */
  elegido(prod: Articulo, indice: string) {

      this.cajas = Number((document.getElementById('textUnidades_' + indice) as HTMLInputElement).value);
      let estado = 0;
      const auxProd = prod.unidades;
      if (this.pedidoService.checkFormato(prod.formato)) {
        prod.unidades = (this.pedidoService.calculaFormato(prod.formato) * this.cajas).toString();
      } else {
        prod.unidades = (this.cajas * Number(prod.unidades)).toString();
        if (Number(prod.id_articulo) === -1) {
          prod.unidades = this.cajas.toString();
          estado = 1;
        }
      }

      this.pedidoService.anyadeProducto(prod, estado);

      this.cajas = 1;
    prod.unidades = auxProd;
  }

  /*
* Guarda los items que son multiplos de valor en el array auxFinal, se emplea para mostrar un numero determinados de articulo
 * por pantalla (3)
* En pantallas pequeñas muestra todos
 */
  creaArray(arr: Articulo[], valor: number, per?: number) {
    let i = valor;
    this.pedidoService.auxValor = valor;

      console.log('auxValorAlcrear ' + this.pedidoService.auxValor);
      this.pedidoService.auxScroll = [];
      let tamArray = 4;
      if (this.anchoPantalla > 1100) {
        tamArray = 3;
      }
      for (i; i < arr.length && i < this.pedidoService.auxValor + tamArray; i++) {
        this.pedidoService.auxScroll.push(arr[i]);
      }
  }

  /*
  * Se llama al clicar la flecha izquierda para desplazarse por los articulos
  * @ Input valor: indice del array que contiene todos los articulos que usaremos para saber
  * con que articulos rellenar el array mostrado por pantalla
   */
  scrollIzquierda(valor: number) {
    let tamArray = 4;
    if (this.anchoPantalla > 1100) {
      tamArray = 3;
    }

    if (valor === tamArray) {
      this.pedidoService.auxValor = valor - 0;
      this.creaArray(this.pedidoService.auxFinal, this.pedidoService.auxValor);
    }
    if (valor > 0) {
      this.pedidoService.auxValor = valor - tamArray;
      this.creaArray(this.pedidoService.auxFinal, this.pedidoService.auxValor);
    }
  }

  /*
 * Se llama al clicar la flecha derecha para desplazarse por los articulos
 * @ Input valor: indice del array que contiene todos los articulos que usaremos para saber
 * con que articulos rellenar el array mostrado por pantalla
  */
  scrollDerecha(valor: number) {
    let tamArray = 4;
    if (this.anchoPantalla > 1100) {
      tamArray = 3;
    }

    if (valor < this.pedidoService.auxFinal.length - tamArray) {
      this.pedidoService.auxValor = valor + tamArray;
      this.creaArray(this.pedidoService.auxFinal, this.pedidoService.auxValor);
    }
  }

  // accion lanzada cuando se desliza el dedo por la pantalla
  pan(currentIndex: number, action = this.PAN_ACTION.RIGHT) {
    // out of range
    if (currentIndex > this.pedidoService.auxFinal.length || currentIndex < 0) { return; }

    // pan right equivale a scroll left
    if (action === this.PAN_ACTION.RIGHT) {
      console.log('derecha' + this.pedidoService.auxFinal.length);
      console.log(currentIndex);
      this.scrollIzquierda(currentIndex);
    }

    // swipe left, scroll right
    if (action === this.PAN_ACTION.LEFT) {
      console.log('izquierda' + this.pedidoService.auxFinal.length);
      console.log(currentIndex);
      this.scrollDerecha(currentIndex);
    }
  }

  /*
  * Funcion llamada al teclear en el input o borrar
   */
  onKey(event: any) {
    let texto = ((document.getElementById("textprod") as HTMLInputElement).value);
    if (texto === '') {
      if (this.categoria === -1) {
        let arr = this.articulos;
        this.creaArray(arr, 0);
      } else {
        this.filtraCategoria(this.categoria.toString(), 1);
        // this.creaArray(this.pedidoService.aux, 0);
      }
    } else {
      this.filtrado(texto);
    }
  }

  /*
* Filtra por los elementos que contengan los caracteres
* @input filtro: caracteres pasados
 */
  filtrado(filtrado: string) {
    // this.pedidoService.auxScroll = [];
    let auxArr = [];
    // this.pedidoService.auxFinal = [];
    const filtro = filtrado.toLowerCase();
    this.filtraCategoria(this.categoria.toString(), 1);
    for (let entry of this.pedidoService.auxFinal) {
      if (((entry.codigo_articulo).toLowerCase().indexOf(filtro) >= 0) || ((entry.descripcion).toLowerCase().indexOf(filtro) >= 0)) {
        // console.log("cliente" + entry.id);
        auxArr.push(entry);
        // this.pedidoService.auxFinal.push(entry);
      }
    }
    this.pedidoService.auxFinal = auxArr;
    this.creaArray(auxArr, 0);
  }

  /*
 * Función activada al cambiar tamaño pantalla, permite modificar el array según si la pantalla esta en modo movil o ordenador
  */
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (this.largoPantalla === undefined || this.largoPantalla === undefined) {
      this.cambiaAncho();
    } else {
      if (((this.anchoPantalla < 1101) && window.innerWidth > 1100) || ((this.anchoPantalla > 1100) && window.innerWidth < 1101)) {
        this.cambiaAncho();
        this.rellenaArray();
      }
    }
  }

  /*
  * Cambia las variables que indican alto y ancho al modificar el tamaño de pantalla
   */
  cambiaAncho() {
    this.largoPantalla = window.innerHeight;
    this.anchoPantalla = window.innerWidth;
  }

  // Llena el array al inicializar la pagina y al cambiar de modo ordenador a modo movil y viceversa
  rellenaArray() {
    console.log('rellena');
    if (this.categoria === undefined) {
      this.creaArray(this.pedidoService.auxFinal, this.pedidoService.auxValor);
    } else {
      this.cambiaAncho();
      this.creaArray(this.pedidoService.aux, this.pedidoService.auxValor);
    }
  }

  /*
* Llama a servicio para convertir de base64 a blob y mostrar el blob
 */
  meteImagen(datob64: string, tipo: string): string {
    console.log('metiendo ' + this.pedidoService.auxScroll[0].descripcion + ' ' + this.pedidoService.auxScroll[1].descripcion + ' ' + this.pedidoService.auxScroll[2].descripcion + ' ' + this.pedidoService.auxValor);
    return this.imagenService.meteImagen(datob64, tipo);
  }
}
