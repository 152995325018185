// Maneja IndexeDB
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { throwError} from 'rxjs';

@Injectable()
export class BDService {

private _indexedDB: any;
private _dbName: string;

/*
* Incializa los parametros
 */
constructor() {
    this._indexedDB = indexedDB;
    this._dbName = 'db'; // por defecto
}

/*
* Permite introducir el nombre de la BD
* @input nombre: nombre de la BD
 */
setName(nombre: string): void {
    if (nombre.length > 0 && nombre !== undefined) {
        this._dbName = nombre;
    } else {
        console.log('Error: nombre de la base de datos incorrecto');
    }
}

/*
* actualiza una fila de una tabla
* @input source: nombre de la tabla
* @input object: datos que se introduciran en la fila
 */
put(source: string, object: any): Observable<any> {
    let self = this;

    return Observable.create((observer: any) => {
        this.open().subscribe((db: any) => {
            let tx = db.transaction(source, "readwrite");
            let store = tx.objectStore(source);
            store.put(object);

            tx.oncomplete = () => {
                observer.next(object);
                db.close();
                observer.complete();
            };
            db.onerror = (e:any) => {
                db.close();
                self.handleError('IndexedDB put error: ' + e.target.errorCode);
            }
        });
    });
};

  /*
  * añade una fila a una tabla
  * @input source: nombre de la tabla
  * @input object: datos que se introduciran en la fila
   */
post(source: string, ob: any): Observable<any> {
    let self = this;
    const object = ob;

    return Observable.create((observer: any) => {
        this.open().subscribe((db: any) => {
                let tx = db.transaction(source, 'readwrite');
                let store = tx.objectStore(source);

                let request = store.add(object);

                    request.onsuccess = (e:any) => {
                        observer.next(e.target.result);
                        db.close();
                        observer.complete();
                    };
                    db.onerror = (e: any) => {
                        console.log('Error posteando ' + source  + e.target + e.message);
                        db.close();
                        self.handleError('IndexedDB post error: ' + e.target.errorCode);
                    };
        });
    });
};

  /*
  * obtiene una fila de una tabla
  * @input source: nombre de la tabla
  * @input id: dato correspondiente a la CP
  * nombre: nombre de la CP, por defecto seria id_idx pero se puede cambiar al crear el esquema
   */
get(source: string, id: string, nombre?: string): Observable<any> {
    let self = this;

   if(nombre == " ") {
      nombre = "id_idx";
    }
    return Observable.create((observer: any) => {
        this.open().subscribe((db: any) => {
            let tx = db.transaction(source, 'readonly');
            let store = tx.objectStore(source);
            let index = store.index(nombre);
            let request = index.get(id);
          // let request = index.openCursor(id);

            request.onsuccess = () => {
                observer.next(request.result);
                db.close();
                observer.complete();
            };
            db.onerror = (e:any) => {
                db.close();
                self.handleError('IndexedDB get error: ' + e.target.errorCode);
            }
        });
    });
};

  /*
  * Obtiene todas las filas de una tabla
  * @input source: nombre de la tabla
  * @input nombre: nombre de la CP, por defecto seria id_idx pero se puede cambiar al crear el esquema
   */
all(source: string, nombre: string, filter?: any): Observable<any[]> {
    let self = this;

    if (nombre === " ") {
      nombre = 'id_idx';
    }
    return Observable.create((observer: any) => {
        this.open().subscribe((db: any) => {
          let tx = db.transaction(source, 'readonly');
          let store = tx.objectStore(source);
          let index = store.index(nombre);

          /*if (filter !== undefined) {
            let requesta = this._indexedDB.open(this._dbName);

            requesta.onupgradeneeded = () => {
              store = tx.objectStore(source);
              store.createIndex('indexSort', filter, {unique: false});
              index = store.index(store);
              store.getAllKeys();
            };
            console.log('requesta va' + source);
          } else {
            console.log('es undefined' + source);
          }*/

            let request = index.openCursor();
            let results: any[] = [];

            request.onsuccess = function () {
                let cursor = request.result;
                if (cursor) {
                    results.push(cursor.value);
                    cursor.continue();
                } else {
                    observer.next(results);
                    db.close();
                    observer.complete();
                }
            };
            db.onerror = (e: any) => {
                db.close();
                self.handleError('IndexedDB get all error: ' + e.target.errorCode);
            }
        });
    });
};

  /*
  * elimina una fila de una tabla
  * @input source: nombre de la tabla
  * @input id: dato correspondiente a la CP
   */
remove(source: string, id: string): Observable<any> {
    let self = this;

    return Observable.create((observer: any) => {
        this.open().subscribe((db: any) => {
            let tx = db.transaction(source, 'readwrite');
            let store = tx.objectStore(source);

            store.delete(id);

            tx.oncomplete = (e:any) => {
                observer.next(id);
                db.close();
                observer.complete();
            };
            db.onerror = (e:any) => {
                db.close();
                self.handleError('IndexedDB borrar error: ' + e.target.errorCode);
            }
        });
    });
};

  /*
  * elimina una tabla
  * @input source: nombre de la tabla
   */
  borrarTabla(source: string): Observable<any> {
    let self = this;

    return Observable.create((observer: any) => {
      this.open().subscribe((db: any) => {
        let tx = db.transaction(source, 'readwrite');
        let store = tx.objectStore(source);

        store.clear();

        tx.oncomplete = (e:any) => {
          observer.next();
          db.close();
          observer.complete();
        };
        db.onerror = (e:any) => {
          db.close();
          self.handleError('IndexedDB error borrando tabla: ' + e.target.errorCode);
        }
      });
    });
  };

  /*
  * elimina las tablas pasadas
  * @input sources: nombres de las tablas
   */
  borrarTablas(sources: string[]): Observable<any> {
    let self = this;

    return Observable.create((observer: any) => {
      this.open().subscribe((db: any) => {
        for (const source of sources) {
          let tx = db.transaction(source, 'readwrite');
          let store = tx.objectStore(source);

          store.clear();

          tx.oncomplete = (e: any) => {
            observer.next();
            db.close();
            observer.complete();
          };
          db.onerror = (e: any) => {
            db.close();
            self.handleError('IndexedDB error borrando tabla: ' + e.target.errorCode);
          };
        }
      });
    });
  };

/*
* Devuelve el número de filas de una tabla
* @input source: nombre de la tabla
 */
count(source: string): Observable<number> {
    let self = this;

    return Observable.create((observer: any) => {
        this.open().subscribe((db: any) => {
            let indexName = 'id_idx';
            let tx = db.transaction(source, 'readonly');
            let store = tx.objectStore(source);
            let index = store.index(indexName);
            let request = index.count();

            request.onsuccess = () => {
                observer.next(request.result);
                db.close();
                observer.complete();
            };
            db.onerror = (e: any) => {
                db.close();
                self.handleError('IndexedDB count error: ' + e.target.errorCode);
            }
        });
    });
};

/*
* Crea la BD
* @input schema: contiene la estructura de la BD
 */
create(schema?: any[]): Observable<any> {
  let self = this;

  return Observable.create((observer: any) => {
    let request = this._indexedDB.open(this._dbName);

    request.onupgradeneeded = () => {
      // The database did not previously exist, so create object stores and indexes.
      let db = request.result;

      for (let i = 0; i < schema.length; i++) {

        let store;
        if (schema[i].indexes !== undefined) {
          store = db.createObjectStore(schema[i].name, {keyPath: schema[i].indexes[0]});
          store.createIndex(schema[i].indexes[0], schema[i].indexes[0], {unique: true});
          for (let j = 1; j < schema[i].indexes.length; j++) {
            let index = schema[i].indexes[j];
            store.createIndex(`${index}`, index, {unique: false});
          }
        } else {
          store = db.createObjectStore(schema[i].name, {keyPath: 'id', autoIncrement: true});
          store.createIndex('id_idx', 'id', {unique: true});
        }

        if (schema[i].seeds !== undefined) {
          for (let j = 0; j < schema[i].seeds.length; j++) {
            let seed = schema[i].seeds[j];
            store.put(seed);
          }
        }
      }

      observer.next('done');
      observer.complete();
    };

    request.onerror = () => {
      self.handleError(request.error);
    }

    request.onsuccess = () => {
      let db = request.result;
      db.close();
    }
  });
}

/*
* Elimina la BD
 */
clear(): Observable<any> {
    let self = this;
    return Observable.create((observer: any) => {
        let request = this._indexedDB.deleteDatabase(this._dbName);

        request.onsuccess = () => {
            observer.next('done');
            observer.complete();
        }
        request.onerror = () => {
            self.handleError('Could not delete indexed db.');
        };
        request.onblocked = () => {
            self.handleError('Couldn not delete database due to the operation being blocked.');
        };
    });
}

/*
* Funcion para lanzar errores
* @input msg: error a lanzar
 */
private handleError(msg: string) {
    console.error(msg);
    // return Observable.throw(msg);
    return throwError(msg);
}

/*
* Abre BD
 */
private open(): Observable<any> {
    let self = this;

    return Observable.create((observer: any) => {

        let request = this._indexedDB.open(this._dbName);

        request.onsuccess = () => {
            observer.next(request.result);
            observer.complete();
        }
        request.onerror = () => {self.handleError(request.error);
        }
    });
}

/*
* Obtiene última fila BD
* @input source: nombre de la tabla
* @input nombre: nombre de la CP, por defecto seria id_idx pero se puede cambiar al crear el esquema
 */
getUltimo(source: string, nombre?: string): Observable<any> {
    let self = this;

    if(nombre == " ") {
      nombre = "id_idx";
    }

    return Observable.create((observer: any) => {
        this.open().subscribe((db: any) => {
            let tx = db.transaction(source, "readonly");
            let store = tx.objectStore(source);
            let index = store.index(nombre);
            let openCursorRequest = index.openCursor(null, 'next');

            openCursorRequest.onsuccess = () => {
              if(openCursorRequest.result == null) {
                observer.next(0);
              } else {
                observer.next(openCursorRequest.result.value);
              }
                db.close();
                observer.complete();
            };
            db.onerror = (e:any) => {
                db.close();
                self.handleError("IndexedDB error: " + e.target.errorCode);
            }
        });
    });
};
}
