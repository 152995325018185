// vista en detalle del producto al seleccionarlo desde el catalogo
import { Component, OnInit } from '@angular/core';
import {Articulo} from '../producto/Articulo';
import {BDService} from '../data/bd.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Categoria} from '../categoria/categoria';
import {ComunicacionService} from '../comunicacion/comunicacion.service';
import {ImagenService} from '../imagen/imagen.service';
import {PedidoService} from '../pedido/pedido.service';

@Component({
  selector: 'app-detalle-articulo',
  templateUrl: './detalle-articulo.component.html',
  styleUrls: ['./detalle-articulo.component.css']
})
export class DetalleArticuloComponent implements OnInit {

  articulo: Articulo;

  bdService: BDService;

  private subscripcion: any;
  id: string;

  categoria: Categoria;
  unidades: number;

  constructor(private bdServic: BDService, private route: ActivatedRoute, private router: Router, private comunicacionService: ComunicacionService, private imagenService: ImagenService, private pedidoService: PedidoService) {
    this.articulo = new  Articulo();
    this.categoria = new Categoria();
    this.bdService = bdServic;
  }

  /*
  * Obtiene articulo de la url y encuentra su categoria
   */
  ngOnInit() {
    let self = this;
    this.subscripcion = this.route.params.subscribe(params => {
      this.id = params['id'];

      self.bdService.get('Articulos', this.id, "id_articulo").subscribe(
        (res) => {
          this.articulo = res,
            this.cogeCategoria(res.codigo_categoria),
            this.unidades = this.pedidoService.calculaFormato(this.articulo.formato);
        },
        (err) => console.log(err),
        () => console.log('Done')
      );
    });
  }

  /*
  * Encuentra categoria
  * @input cat: identificador de categoria
   */
  cogeCategoria(cat: string) {
    let self = this;

    if (cat !== "") {
      self.bdService.get('Categorias', cat, "codigo_categoria").subscribe(
        (res) => {
          this.categoria = res
        },
        (err) => console.log(err),
        () => console.log('Done')
      );
    }
  }

  /*
* Llama a servicio para convertir de base64 a blob y mostrar el blob
 */
  meteImagen(datob64: string, tipo: string): string{

    return this.imagenService.meteImagen(datob64, tipo);
  }

  /*
* Se llama desde el boton volver para retornar a la lista
 */
  Volver() {
    this.router.navigate(['/' , 'productoLista']);
  }
  redondeaNumero(num: string, decim: number): string {
    return (Number(num).toFixed(decim));
  }
}
