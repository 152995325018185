/*Lista clientes y permite pedir*/
import { Component, OnInit} from '@angular/core';
import { BDService } from '../data/bd.service';
import {Cliente} from './cliente';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})

export class ClienteComponent implements OnInit {

  clientes: Cliente[];
  aux: Cliente[];
  // databaseCreated: boolean;
  bdService: BDService;
  page: number;
  pageSize: number;
  /*
  * @Input bdService : servicio que gestiona indexedBD localizado en /data
  * @Input router: controla navegacion entre paginas
   */
  constructor(private bdServic: BDService, private router: Router) {
      this.clientes = [];
      this.aux = [];
      this.bdService = bdServic;
      this.page = 1;
      this.pageSize = 15;
  }

  /*
  * Inicializa array de clientes
   */
  ngOnInit() {
      // Create the IndexedDB database and perform few operations.
      let self = this;
      this.bdService.setName('BDPedidos');

       // Extrae cliente
       self.bdService.all('Clientes', 'codigo_cliente').subscribe(Clientes => self.clientes = Clientes);
       self.bdService.all('Clientes', 'codigo_cliente').subscribe(Clientes => self.aux = Clientes);
  }

  /*
  * Evento lanzado al teclear en el input para filtrar clientes
   */
  onKey(event: any) {
      let texto = ((document.getElementById("textclien") as HTMLInputElement).value);
      if (texto === '') {
          this.bdService.all('Clientes', 'id_cliente').subscribe(cliens => this.aux = cliens);
      } else {
          this.filtrado(texto);
      }
  }

  /*
  * Filtra por los elementos que contengan los caracteres
  * @input filtro: caracteres pasados
   */
  filtrado(filtrado: string) {
      this.aux = [];
      const filtro = filtrado.toLowerCase();
      for (const entry of this.clientes) {
          if (((entry.codigo_cliente).toLowerCase().indexOf(filtro) >= 0) || ((entry.nombre).toLowerCase().indexOf(filtro) >= 0) ||
            ((entry.direccion).toLowerCase().indexOf(filtro) >= 0) || ((entry.poblacion).toLowerCase().indexOf(filtro) >= 0) ||
            (('z' + (entry.codigo_transportista)).toLowerCase().indexOf(filtro) >= 0) ||
            ((entry.cpostal).toLowerCase().indexOf(filtro) >= 0)) {
            // console.log("cliente" + entry.id);
              this.aux.push(entry);
          }
      }
  }

  /*
  * Reordena el array que se muestra
  * @input clave: indica por que atributo se ordena
   */
  reordena(clave: string) {
    if (clave === 'codigo') {
      this.aux = this.aux.sort((n1, n2) => Number(n1.codigo_cliente) - Number(n2.codigo_cliente));
    } else if (clave === 'nombre') {
      this.aux = this.aux.sort((n1, n2) => {
        if (n1.nombre.toUpperCase() > n2.nombre.toUpperCase()) {
          return 1;
        }

        if (n1.nombre.toUpperCase() < n2.nombre.toUpperCase()) {
          return -1;
        }

        return 0;
      });
    } else if (clave === 'dir') {
      this.aux = this.aux.sort((n1, n2) => {
        if (n1.direccion.toUpperCase() > n2.direccion.toUpperCase()) {
          return 1;
        }

        if (n1.direccion.toUpperCase() < n2.direccion.toUpperCase()) {
          return -1;
        }

        return 0;
      });
    } else if (clave === 'pob') {
      this.aux = this.aux.sort((n1, n2) => {
        if (n1.poblacion.toUpperCase() > n2.poblacion.toUpperCase()) {
          return 1;
        }

        if (n1.poblacion.toUpperCase() < n2.poblacion.toUpperCase()) {
          return -1;
        }

        return 0;
      });
    } else if (clave === 'zona') {
      this.aux = this.aux.sort((n1, n2) => Number(n1.codigo_transportista) - Number(n2.codigo_transportista));
    } else if (clave === 'cp') {
      this.aux = this.aux.sort((n1, n2) => Number(n1.cpostal) - Number(n2.cpostal));
    }
  }
}




