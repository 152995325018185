/*
* Vista en detalle de un producto de la vista catalogo pedido que permite pedirlo
 */
import { Component, OnInit } from '@angular/core';
import {BDService} from '../data/bd.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Articulo} from '../producto/Articulo';
import {Categoria} from '../categoria/categoria';
import {PedidoService} from '../pedido/pedido.service';
import {ImagenService} from '../imagen/imagen.service';
import {el} from '@angular/platform-browser/testing/src/browser_util';

@Component({
  selector: 'app-articulo-pedido',
  templateUrl: './articulo-pedido.component.html',
  styleUrls: ['./articulo-pedido.component.css']
})
export class ArticuloPedidoComponent implements OnInit {

  articulo: Articulo;

  bdService: BDService;
  pedidoService: PedidoService;

  private subscripcion: any;
  id: string;

  categoria: Categoria;

  precioLinPed: string;
  precioCliente: number;
  precioClienteIva: number;
  cajas: number;
  unidades: number;

  /*
 * @Input bdService : servicio que gestiona indexedBD localizado en /data
 * @Input route: obtiene parametros de la url
 * @Input router: controla navegacion entre paginas
 * @Input pedidoService Realiza operaciones necesarias para realizar pedidos y mantiene persistencia de datos al navegar entre componentes
 * @Input imagenService :  servicio para tratar con imagenes
  */
  constructor(private bdServic: BDService, private route: ActivatedRoute, private router: Router, private pedidoSer: PedidoService, private imagenService: ImagenService) {
    this.articulo = new  Articulo();
    this.categoria = new Categoria();
    this.bdService = bdServic;
    this.pedidoService = pedidoSer;
    this.cajas = 1;
    this.unidades = 1;
    this.precioLinPed = '0';
    this.precioCliente = 0;
  }

  /*
  * Obtiene el articulo desde la url, la categoria para el input correspondiente e inicializa el valor por defecto de cantidad
   */
  ngOnInit() {
    let self = this;
    this.subscripcion = this.route.params.subscribe(params => {
      this.id = params['id'];

      this.pedidoService.descuento = '0';

      if (Number(this.id) === -1) {
        this.articulo = new Articulo();
      } else {
        self.bdService.get('Articulos', this.id, "id_articulo").subscribe(
          (res) => {
            this.articulo = res,
              this.cogeCategoria(res.codigo_categoria),
              this.unidades = this.pedidoService.calculaFormato(this.articulo.formato),
              // this.precioCliente = Number(this.articulo.precio),
              this.iniciaPrecioyDescuento(res);
          },
          (err) => console.log(err),
          () => console.log('Done')
        );
      }
    });
  }

  /*
  * Logica para obtener el nombre de la categoria a partir del codigo que provee articulo.codigo_aticulo
  * @Input cat: El codigo del articulo
   */
  cogeCategoria(cat: string) {
    let self = this;

    if (cat !== "") {
      self.bdService.get('Categorias', cat, "codigo_categoria").subscribe(
        (res) => {
          this.categoria = res
        },
        (err) => console.log(err),
        () => console.log('Done')
      );
    }
  }

  /*
  * Función llamada al clicar Añade Producto. LLama a las funciones para añadir articulo al pedido y navega para seguir con el pedido
  * @input articulo: articulo a pedir
   */
  elegido(articulo: Articulo) {
    articulo.unidades = (document.getElementById('cantidad') as HTMLInputElement).value;
    console.log('id articulo ' + articulo.id_articulo);
    if (Number(articulo.id_articulo) === -1) { //Para meter linea con estado de detalle=1
      articulo.descripcion = (document.getElementById('nombre') as HTMLInputElement).value;
      articulo.precio = Number((document.getElementById('precio') as HTMLInputElement).value) / Number(articulo.unidades);
      articulo.precio_mayorista = articulo.precio;
      this.pedidoService.anyadeProducto(articulo, this.cajas);
    } else {
      this.pedidoService.anyadeProducto(articulo, 0);
    }
    // this.pedidoService.eligeProducto(articulo);
    this.pedidoService.setCategoria(Number(articulo.codigo_categoria));
    this.router.navigate(['pedidoCatalogo', this.pedidoService.cliente.id_cliente]);
  }

  /*
  * Se llama desde el boton volver para retornar al pedido sin incluir ese producto
   */
  Volver() {
    this.router.navigate(['pedidoCatalogo', this.pedidoService.cliente.id_cliente]);
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Manejo de alert para cuando se cambia el descuento
  /////////////////////////////////////////////////////////////////////////////////////

  /*
  * Al pulsar que si se cambia el descuento
  * @input cambia: etiqueta de la alert empleada para cerrarlo
  */
  confirm(cambia: any): void {
    this.pedidoService.message = 'Confirmed!';
    cambia.hide();
    this.pedidoService.descuentoAux = this.pedidoService.descuento;
    this.calculaPrecio();
  }

  /*
 * Al pulsar que no se cambia el descuento
 * @input cambia: etiqueta de la alert empleada para cerrarlo
 */
  decline(cambia: any): void {
    this.pedidoService.message = 'Declined!';
    cambia.hide();
    this.pedidoService.descuento = this.pedidoService.descuentoAux;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Imagen
  /////////////////////////////////////////////////////////////////////////////
  /*
  * Llama a servicio para convertir de base64 a blob y mostrar el blob
   */
  meteImagen(datob64: string, tipo: string): string{

    return this.imagenService.meteImagen(datob64, tipo);
  }

  /////////////////////////////////////////////////////////////////////////////////
  // Input precio
  /////////////////////////////////////////////////////////////////////////////////

  /*
  * Tras conseguir el articulo por el id, esta funcion rellena el input del precio al cargar el componente
   */
  iniciaPrecioyDescuento(articulo: Articulo) {
    if (Number(this.pedidoService.cliente.mayorista) === 1) {
      this.precioCliente = Number(this.articulo.precio_mayorista);
      this.precioClienteIva = Number(this.articulo.precio_mayorista_con_iva);
    } else {
      this.precioCliente = Number(this.articulo.precio);
      this.precioClienteIva = Number(this.articulo.precio_con_iva);
    }

    if (this.pedidoService.v === 1) {
      this.precioLinPed = (this.precioCliente * Number(this.unidades)).toString();
    } else {
      this.precioLinPed = ((this.precioClienteIva -
        ((this.precioClienteIva * Number(this.pedidoService.descuento)) / 100)) * Number(this.unidades)).toString();
    }

    if ( articulo.predef2 !== '') {
      console.log('art2' + articulo.predef2);
      this.pedidoService.descuento = this.articulo.predef2;
    } else {
      this.pedidoService.descuento = '0';
    }

    this.pedidoService.descuentoAux = this.pedidoService.descuento;
  }

  /*
  * Al cambia la cantidad o el descuento recalcula el precio
   */
  calculaPrecio() {
    let cant = (document.getElementById('cantidad') as HTMLInputElement).value;

    if (this.pedidoService.v === 1) {
        this.precioLinPed = (this.precioCliente * Number(cant)).toString();
    } else {
        let desc = (document.getElementById('descuentoText') as HTMLInputElement).value;
        if (desc !== '') {
          this.precioLinPed = ((this.precioClienteIva -
            ((this.precioClienteIva * Number(desc)) / 100)) * Number(cant)).toString();
        }
    }
  }

  /*
  * Permite modificar la cantidad de producto del input cajas
   */
  cambiaArticuloCajas() {
    const cajas = (document.getElementById('cajas') as HTMLInputElement).value;
    this.cajas = Number(cajas);
    if (cajas !== '' && Number(this.id) !== -1) {
      (document.getElementById('cantidad') as HTMLInputElement).value = (this.cajas * this.pedidoService.calculaFormato(this.articulo.formato)).toString();
      this.calculaPrecio();
    }
  }

  /*
  * Permite modificar la cantidad de producto del input cantidad
   */
  cambiaArticuloUnidades() {
    const unids = (document.getElementById('cantidad') as HTMLInputElement).value;

    if (unids !== '' && Number(this.id) !== -1) {
      this.cajas = Math.floor(Number(unids) / this.pedidoService.calculaFormato(this.articulo.formato));
      (document.getElementById('cajas') as HTMLInputElement).value = this.cajas.toString();
      this.calculaPrecio();
    }
  }
}
